import {Component, Input, Output, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {Article, ArticleCookie} from '../../../interfaces/article';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthStorageService} from '../../../services/auth/auth-storage.service';
import {ShoppingCarService} from '../../../services/shopping-car.service';
import {SummaryOrder} from '../../../interfaces/summary-order';
import {NgbCarousel, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CompanyConf} from '../../../interfaces/company-conf';
import {PriceRoute} from "../../../interfaces/price-route";
import {LocalStorageService} from "../../../services/local-storage.service";
import {HttpClient} from '@angular/common/http';
import {getWindow} from 'ssr-window';
import {Router} from '@angular/router';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})

export class ArticleComponent implements OnInit{

  constructor(private router: Router, private authStorageService: AuthStorageService, private shoppingCarService: ShoppingCarService,
              private modalService: NgbModal,
              private localStorage: LocalStorageService) {
    router.events.subscribe((val) => {
      if (this.urlActual != this.router.url) {
        this.urlActual = this.router.url;

        var element = document.getElementById("carousel2");
        element.classList.remove("active");
        element.classList.remove("ng-star-inserted");


        var element = document.getElementById("carousel1");
        element.classList.remove("active");
        element.classList.add("active");
        this.newNavigationFn();
      }
    });
  }

  @ViewChild('carousel') carousel!: NgbCarousel;
  @Input() navigationRoute;
  @Input() article: Article;
  @Output() closeWindow: EventEmitter<boolean> = new EventEmitter();
  @Input() minPrice: number;
  @Input() companyConf: CompanyConf;
  @Input() dropDownGauge: boolean;
  @Input() paymentMethod: string;
  @Input() priceRoute: PriceRoute;
  @Input() selectKgsOriginal;
  @Input() selectUnitsOriginal;
  @Input() isUser: boolean;
  @Output() numberArticles = new EventEmitter<number>();
  @Input() indicateLumps: boolean;
  @Input() articleId: string;
  @Input() homeDelivery: boolean;
  @Input() variasFotos: boolean;

  pendientePago = 0;
  totalPrice = 0;
  totalTransport = 0;
  quantityUdForm  = new FormGroup({
                                 quantity: new FormControl(null, [Validators.required, Validators.min(0.25)]),
  });
  cutForm = new FormGroup({
    cut: new FormControl(null, [Validators.required]),
  });

  // Lumps fish
  numberPieces = 0;
  maxNumberPieces = 0;
  minNumberPieces = 0;

  summary: SummaryOrder;
  differenceMinPrice: number;

  selectFormat;
  selectFormatName;
  cutValid = false;

  minimum;
  maximum;
  useMinMax = false;

  urlActual = "";


  ngOnInit() {
    this.newNavigationFn();
    this.urlActual = this.router.url;
  }

  newNavigationFn() {
    this.numberPieces = 0;
    this.initialVariablesValues();
    this.initialValues();
    this.defaultSelectValues();
    this.calculatePriceUd();
  }



  initialVariablesValues(){
    this.totalPrice = 0;
    this.navigationRoute = null;

    // Initial form values
    this.quantityUdForm = new FormGroup({
      quantity: new FormControl(null, [Validators.required, Validators.min(0.25)]),
    });

    this.cutForm = new FormGroup({
      cut: new FormControl(null, [Validators.required]),
    });
  }

  initialValues () {
    this.useMinMax = false;
    this.minimum = null;
    this.maximum = null;

    this.selectFormat = this.article.format;
    if (this.selectFormat == 1) {
      this.selectFormatName = 'Kilos';
    } else {
      this.selectFormatName = 'unidades';
    }

    if (this.article.minimum != 0 && this.article.maximum != 0) {
      this.useMinMax = true;
      this.minimum = this.article.minimum;
      this.maximum = this.article.maximum;
    }

    if (this.article.tiposCorte != undefined && this.article.tiposCorte.length > 0) {
      // @ts-ignore
      this.cutForm.controls['cut'].setValue(this.article.tiposCorte[0].id);
    }
  }

  addQuantity(format: any) {
    if (this.quantityUdForm.controls['quantity'].value == undefined) {
      this.quantityUdForm.controls['quantity'].setValue(1);
    } else {
      if (format == 1) { // Kilos
        this.quantityUdForm.controls['quantity'].setValue(this.quantityUdForm.controls['quantity'].value + 0.25);
      } else {
        this.quantityUdForm.controls['quantity'].setValue(this.quantityUdForm.controls['quantity'].value + 1);
      }
    }

  }

  subtractQuantity(format: any) {
    if (this.quantityUdForm.controls['quantity'].value == undefined) {
      this.quantityUdForm.controls['quantity'].setValue(0.25);
    } else if (this.quantityUdForm.controls['quantity'].value > 0) {
      if (format == 1) { // Kilos
        this.quantityUdForm.controls['quantity'].setValue(this.quantityUdForm.controls['quantity'].value - 0.25);
      } else {
        this.quantityUdForm.controls['quantity'].setValue(this.quantityUdForm.controls['quantity'].value - 1);
      }
    }
  }

  defaultSelectValues(){
    this.quantityUdForm.controls['quantity'].setValue(1, {onlySelf: true});
  }



  calculatePriceUd() {
    let totalP = 0;
    if (this.quantityUdForm.controls['quantity'].valid && this.companyConf != undefined) {
      totalP = parseFloat(this.quantityUdForm.controls['quantity'].value) *
                        ((this.article.format != '1' && this.article.approxWeight > 0 && this.companyConf.utilizarMultiplesFormatos) ?
                          (this.article.priceIva * this.article.approxWeight) : this.article.priceIva);
      this.totalPrice = parseFloat(((((totalP) + Number.EPSILON) * 100) / 100).toFixed(2));
      if (this.useMinMax && this.indicateLumps && this.article.format == '1') {
        this.numberPieces = 0;
        this.maxNumberPieces = Math.trunc(this.quantityUdForm.controls['quantity'].value / this.minimum);

        if (this.quantityUdForm.controls['quantity'].value % this.maximum != 0) {
          this.minNumberPieces = Math.trunc(this.quantityUdForm.controls['quantity'].value / this.maximum) + 1;
        } else {
          this.minNumberPieces = this.quantityUdForm.controls['quantity'].value / this.maximum;
        }
        this.numberPieces = this.minNumberPieces;
      }
    } else {
      this.totalPrice = 0;
    }
  }

  addArticleShoppingCar(content) {
    const arg: ArticleCookie = new ArticleCookie(this.article.id, 0, this.article.name, this.selectFormat,
      null, this.article.priceIva, this.article.nameClient, this.article.group, this.article.family,
      0, this.numberPieces, this.totalPrice, null, null, null);
    if (this.cutForm.controls['cut'].value != null) {
      const cut = this.article.tiposCorte.find(e => e.id == this.cutForm.controls['cut'].value);
      arg.cutType = this.cutForm.controls['cut'].value;
      arg.cutName = cut.name;
    }

    if (!this.dropDownGauge && this.quantityUdForm.controls['quantity'].value != null) {
      if (this.article.format != '1') {
        arg.quantity = parseFloat(this.quantityUdForm.controls['quantity'].value);
        if (this.article.format == '2' && arg.quantity > 0 && this.article.approxWeight > 0) {
          arg.lumps = arg.quantity;
          arg.quantity = arg.quantity * this.article.approxWeight;
        }
      } else if (this.article.format == "1") {
        let approxWeight = 1;
        if (this.article.approxWeight != null || this.article.approxWeight != 0) {
          approxWeight = this.article.approxWeight;
        }
        arg.quantity = parseFloat(this.quantityUdForm.controls['quantity'].value) * approxWeight; // Lumps * aprox weiight
        arg.lumps = this.quantityUdForm.controls['quantity'].value;
      } else {
        arg.quantity = parseFloat(this.quantityUdForm.controls['quantity'].value);
      }
    }

    if (!this.localStorage.addLocalStorageArticle(arg)) {
      this.shoppingCarService.addArticle(this.article.id, this.selectFormat, arg.quantity, arg.lumps,
        arg.cutType, false).subscribe(
        (data: SummaryOrder) => {
          this.getPrices(data);
          this.modalService.open(content, {backdropClass: 'light-grey-backdrop', centered: true});
        }
      );
    }

    if (this.authStorageService.getUser() == null) {
      const arts = this.localStorage.getLocalStorageArticles();
      let differenceMinPrice: SummaryOrder;
      let sumKg = 0;
      if (Array.isArray(arts)) {
        let sumPrice = 0;
        arts.forEach(e => {
          sumPrice = ((sumPrice * 10) + (e.totalIvaPrice * 10)) / 10;
          if (e.format == '1'){
            sumKg += e.quantity;
          }
        });
        sumPrice = parseFloat((Math.round((sumPrice + Number.EPSILON) * 100) / 100).toFixed(2));

        differenceMinPrice = new SummaryOrder(0, sumPrice, sumKg, arts.length.toString());
      } else {
        if (arts.format == '1'){
          sumKg = arts.quantity;
        }
        differenceMinPrice = new SummaryOrder(0, arts.totalIvaPrice, sumKg, '1');
      }

      this.totalTransport = 0;
      if (this.priceRoute.deliveryKg != 0 && this.priceRoute.deliveryPrice != 0 && this.companyConf.useRoutes) {
        this.totalTransport = Math.ceil(sumKg/this.priceRoute.deliveryKg) * this.priceRoute.deliveryPrice;
        if (this.priceRoute.fixedPrice > 0) {
          this.totalTransport += this.priceRoute.fixedPrice;
        }
        differenceMinPrice.totalPriceIva += this.totalTransport;
      } else if (this.priceRoute.minKg != 0 && this.priceRoute.deliveryPrice != 0) {
        this.totalTransport = this.priceRoute.deliveryPrice;
        differenceMinPrice.totalPriceIva += this.totalTransport;
      } else if (this.priceRoute.deliveryPrice != 0 && (differenceMinPrice.totalPriceIva - this.totalTransport) < this.minPrice) {
        this.totalTransport = this.priceRoute.deliveryPrice;
        differenceMinPrice.totalPriceIva += this.totalTransport;
      }
      this.getPrices(differenceMinPrice);
      this.modalService.open(content, {backdropClass: 'light-grey-backdrop', centered: true});
    }
  }

  getPrices(data: SummaryOrder) {
    data.totalPriceIva = (Math.round((data.totalPriceIva  + Number.EPSILON) * 100) / 100);
    this.summary = data;
    this.differenceMinPrice = (Math.round((this.minPrice - this.summary.totalPriceIva + Number.EPSILON) * 100) / 100);

    this.totalTransport = 0;
    if (this.priceRoute.deliveryKg != 0 && this.priceRoute.deliveryPrice != 0) {
      this.totalTransport = Math.ceil(data.sumKg / this.priceRoute.deliveryKg) * this.priceRoute.deliveryPrice;
      if (this.priceRoute.fixedPrice > 0) {
        this.totalTransport += this.priceRoute.fixedPrice;
      }
      this.summary.totalPriceIva += this.totalTransport;
    } else if (this.priceRoute.minKg != 0 && this.priceRoute.deliveryPrice != 0) {
      this.totalTransport = this.priceRoute.deliveryPrice;
      this.summary.totalPriceIva += this.totalTransport;
    } else if (this.priceRoute.deliveryPrice != 0 && (this.summary.totalPriceIva - this.totalTransport) < this.minPrice) {
      this.totalTransport = this.priceRoute.deliveryPrice;
      this.summary.totalPriceIva += this.totalTransport;
    }

    // Envío el número de articulos al componente principal
    this.numberArticles.emit(parseInt(this.summary.numArticles));
    this.closeWindow.emit(true);
  }

  openModalArticle(content) {
    this.addArticleShoppingCar(content);
  }

  openModalTag(content) {
    this.modalService.open(content, {backdropClass: 'light-grey-backdrop', centered: true});
  }

  closeModal(modal) {
    if (this.quantityUdForm.controls['quantity'].value != null) {
      this.quantityUdForm = new FormGroup({
        quantity: new FormControl(null, [Validators.required, Validators.pattern('^[0-9]+$'),
          Validators.min(1)]),
      });
    }
    modal.close();
  }

  cutValidFn() {
    this.cutValid = this.cutForm.controls['cut'].value != null;
  }

  // VALIDATORS FUNCTIONS
  addValue() {
    if (this.numberPieces < this.maxNumberPieces){
      this.numberPieces++;
    }
  }

  subtractValue() {
    if (this.numberPieces > this.minNumberPieces && this.numberPieces > 1){
      this.numberPieces--;
    }
  }

  scrollTop(){
    getWindow().scroll(0,0);
  }

  openModal(modal) {
    this.modalService.open(modal, {backdropClass: 'light-grey-backdrop', centered: true});
  }

  redirectToGrupo(nombre: string) {
    return  '/compra/' + nombre.toLowerCase().split(' ').join('-').split('(').join('').split(')').join('').split('/').join('');
  }

  urlImg(pos: number, url: string): string {
    if (pos == 1) {
      return this.unirCadenasDespuesDelUltimoPunto(url, '_1') ;
    }

    return this.replaceLastUnderscoreWithEmpty(url) ;
  }

  setCarrouselImgNext() {
    if (this.variasFotos) {
      document.getElementById('buttonNext').click();
    }
  }

  setCarrouselImgPrevious() {
    if (this.variasFotos) {
      document.getElementById('buttonPrev').click();
    }
  }


  replaceLastUnderscoreWithEmpty(inputString: string): string {
    const regex = /_(\d+)(?=[^_]*$)/;
    return inputString.replace(regex, '');
  }

  unirCadenasDespuesDelUltimoPunto(cadena1: string, cadena2: string): string {
    const ultimoPuntoIndex = cadena1.lastIndexOf('.');

    if (ultimoPuntoIndex != -1) {
      return cadena1.substring(0, ultimoPuntoIndex)  + cadena2 + cadena1.substring(ultimoPuntoIndex);
    } else {
      return cadena1 + cadena2;
    }
  }
}
