import {createAction, props} from '@ngrx/store';

export const leerEmpresaInfo = createAction('[Empresa] leerEmpresaInfo');
export const obtenerEmpresaInfo = createAction('[Empresa] obtenerEmpresaInfo', props<{ value: any }>());
export const obtenerEmpresaInfoError = createAction('[Empresa] obtenerEmpresaInfo', props<{ error: any }>());



export const leerEmpresaInfoBasica = createAction('[EmpresaBasica] leerEmpresaInfo');
export const obtenerEmpresaInfoBasica = createAction('[EmpresaBasica] obtenerEmpresaInfo', props<{ value: any }>());
export const obtenerEmpresaInfoErrorBasica = createAction('[EmpresaBasica] obtenerEmpresaInfo', props<{ error: any }>());


export const leerEmpresaInfoTotal = createAction('[EmpresaTotal] leerEmpresaInfo');
export const obtenerEmpresaInfoTotal = createAction('[EmpresaTotal] obtenerEmpresaInfo', props<{ value: any }>());
export const obtenerEmpresaInfoErrorTotal = createAction('[EmpresaTotal] obtenerEmpresaInfo', props<{ error: any }>());


export const leerUsuarioInfoTotal = createAction('[UsuarioTotal] leerUsuarioInfo');
export const obtenerUsuarioInfoTotal = createAction('[UsuarioTotal] obtenerUsuarioInfo', props<{ value: any }>());
export const obtenerUsuarioInfoErrorTotal = createAction('[UsuarioTotal] obtenerUsuarioInfo', props<{ error: any }>());


export const leerPaises = createAction('[Pais] leerPaises');
export const obtenerPaises = createAction('[Pais] obtenerPaises', props<{ value: any[] }>());
export const obtenerPaisesError = createAction('[Pais] obtenerPaises', props<{ error: any }>());


export const leerArticulos = createAction('[TodosArticulos] leerArticulos');
export const obtenerArticulosInfo = createAction('[TodosArticulos] obtenerArticulosInfo', props<{ value: any[] }>());
export const modificarArticulosError = createAction('[TodosArticulos] obtenerArticulos', props<{ error: any }>());

export const leerSuperGrupo = createAction('[SuperGrupos] leerGrupos');
export const obtenerSuperGrupo = createAction('[SuperGrupos] obtenerGrupos', props<{ value: any[] }>());
export const obtenerSuperGrupoError = createAction('[SuperGrupos] obtenerGrupos', props<{ error: any }>());



export const leerFamilias = createAction('[Familiass] leerFamilias');
export const obtenerFamilias = createAction('[Familiass] obtenerFamilias', props<{ value: any[] }>());
export const obtenerFamiliasError = createAction('[Familiass] obtenerFamilias', props<{ error: any }>());
