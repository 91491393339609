import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from '../../../app.config';

@Injectable({
  providedIn: 'root'
})
export class WebEstaticaService {

  constructor(private httpClient: HttpClient, private config: AppConfig) {}

  public getPosicionamiento() {
    return this.httpClient.get("assets/posicionamiento.json");
  }

  public getInicioJSON() {
    return this.httpClient.get("assets/web-estatica/inicio/json/inicio.json");
  }

  public getContactoJSON() {
    return this.httpClient.get("assets/web-estatica/inicio/json/contacto.json");
  }

  public getAcercaDeJSON() {
    return this.httpClient.get("assets/web-estatica/inicio/json/acerca-de.json");
  }

  getPoliticasJSON() {
    return this.httpClient.get("assets/web-estatica/inicio/json/politicas.json");
  }

  public getClubJSON() {
    return this.httpClient.get("assets/web-estatica/inicio/json/club.json");
  }

  public getBarraSuperiorJSON() {
    return this.httpClient.get("assets/web-estatica/inicio/json/barra-superior.json");
  }

  public getEncuestaJSON() {
    return this.httpClient.get("assets/web-estatica/inicio/json/encuesta.json");
  }
}
