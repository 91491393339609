import {
  Component,
  OnInit, NgZone,
  ElementRef, HostListener, ChangeDetectorRef,
} from '@angular/core';
import {DefaultService} from '../../@core/services/default.service';
import {DomSanitizer} from '@angular/platform-browser';
import {SuperGroup} from '../../@core/interfaces/group';
import {Family} from '../../@core/interfaces/family';
import { Router} from '@angular/router';
import {AuthStorageService} from '../../@core/services/auth/auth-storage.service';
import {Article, DeleteArticle, ProductArticles, QuantityLumpsArticle} from '../../@core/interfaces/article';
import {AuthService} from '../../@core/services/auth/auth.service';
import {UserInfo} from '../../@core/interfaces/user-info';
import {ShoppingCarService} from '../../@core/services/shopping-car.service';
import {MinPrice} from '../../@core/interfaces/min-price';
import {CompanyConf, CompanyInfo} from '../../@core/interfaces/company-conf';
import {PriceRoute} from '../../@core/interfaces/price-route';
import {Country} from '../../@core/interfaces/country';
import {NumberInterface} from '../../@core/interfaces/numberInterface';
import {LocalStorageService} from '../../@core/services/local-storage.service';
import {BasicArticle} from '../../@core/interfaces/article-order';
import {CarArticle} from '../../@core/interfaces/car-article';
import {animate, group, query, style, transition, trigger} from '@angular/animations';
import {EditProfileComponent} from '../../@core/components/auth/edit-profile/edit-profile.component';
import {SeeArticlesComponent} from '../../@core/components/index/see-articles/see-articles.component';
import {OrdersComponent} from '../../@core/components/auth/orders/orders.component';
import {ArticleWindowComponent} from '../../@core/components/index/article-window/article-window.component';
import {ArticlesComponent} from '../../@core/components/index/articles/articles.component';
import {AcercaDeComponent} from '../../@core/components/pagina-estatica/acerca-de/acerca-de.component';
import {AtencionAlClienteComponent} from '../../@core/components/pagina-estatica/atencion-al-cliente/atencion-al-cliente.component';
import {WebEstaticaService} from '../../@core/services/web-estatica/web-estatica.service';
import {Menu} from '../../@core/interfaces/web-estatica/barra-superior';
import {EncuestaComponent} from '../../@core/components/pagina-estatica/encuesta/encuesta.component';
import {obtenerEmpresaInfo, obtenerEmpresaInfoTotal, obtenerFamilias, obtenerPaises, obtenerSuperGrupo} from '../../redux/empresa.actions';
import {Store} from '@ngrx/store';

import {getWindow} from "ssr-window";
import {Posicionamiento} from '../../@core/interfaces/posicionamiento';

const down = [
  group([
    query(':enter', [
      style({ height: 0, opacity: 0 }),
      animate('1s ease-in', style({ opacity:1, transform: 'perspective(200px) translateZ(0px)',}))
    ]),
  ]),
];

@Component({
  selector: 'app-default-container',
  templateUrl: './default-container.component.html',
  styleUrls: ['./default-container.component.scss'],
  animations: [
    trigger('animSlider', [
      transition(':increment , :decrement', down),
    ])
  ],
})
export class DefaultContainerComponent implements OnInit {
  posicionamiento: Posicionamiento | undefined;

  isNavbarCollapsed = true;
  loadedInitialValues = false;
  superGroups: Array<SuperGroup>;
  families: Array<Family> = [];
  countries: Array<Country> = [];
  loadCountries = false;
  articles: Array<Article> = [];
  productArticles: Array<ProductArticles> = [];
  component: SeeArticlesComponent | ArticlesComponent;

  userInfo: UserInfo;
  isUser = false;
  paymentMethod = 'CA';
  minPrice: number;
  priceRoute: PriceRoute = {  deliveryKg: 0, deliveryPrice: 0, fixedPrice: 0, minKg: 0};

  companyConf: CompanyConf;
  datosLegalesEmpresa: CompanyInfo;
  cpNumber;
  notNeedArticles = false;

  textos = [];//["Compra en camino, descarga la app", "Oferta de la semana 5% en frutas"];
  counter: number = 0;

  selectKgs: Array<number> = [];
  selectUnits: Array<number> = [];
  activateMobil: number; // 0 -> init; 1 -> car; 2 -> Mi Profile; 3 -> no activate
  processOrderNav: boolean;

  numberArticles = 0;

  loadingNewArticles = true;

  startTopArticles = false;
  listArticlesNavigation = false;
  arrayListArticles;
  shoppingCarArticlesCp: Array<CarArticle>;
  waitAddArticles = false;

  processOrder: boolean;
  defaultRoute: string;

  state;
  showOffers: boolean;
  newUser: boolean;
  showBottomMenu = true;
  showMenu = false;
  homeDelivery = true;
  menu2: Array<Menu> | undefined;

  selectedOrderSuperGroup: SuperGroup;

  constructor(private defaultService: DefaultService, private sanitizer: DomSanitizer,
              private router: Router, private authStorageService: AuthStorageService,
              private authService: AuthService, private shoppingCarService: ShoppingCarService,
              public element: ElementRef, private localStorageService: LocalStorageService, private _cdr: ChangeDetectorRef,
              private webEstaticaService: WebEstaticaService, private storeEmpresa: Store<any>,
              private ngZone: NgZone) {
  }

  ngOnInit(): void {
    this.obtenerDatosEmpresa();
    this.obtenerPaises();
    this.obtenerSuperGrupos();
    this.obtenerFamilias();
    this.cargarTextos();




    this.defaultRoute = this.localStorageService.getDefaultLink();
    if (this.authStorageService.getTokenType() != 2) {
      this.getMinOrderPrice();
      this.getAllCountries();
    }
  }

  cargarTextos() {
    this.webEstaticaService.getBarraSuperiorJSON().subscribe(
      (data: any) => {
        this.textos = data.publicidad;
        this.menu2 = data.menu2;
        this.siguienteTexto();
      }
    );
  }

  siguienteTexto() {
    if (this.textos != undefined) {
      if (this.textos.length != 0) {
        this.ngZone.runOutsideAngular(() => {
          setTimeout(() => {
            // Use ngZone.run to update the UI inside the Angular zone
            this.ngZone.run(() => {
              if (this.counter == this.textos.length - 1) {
                this.counter = 0;
              } else {
                this.counter += 1;
              }
              this.siguienteTexto();
            });
          }, 2000);
        });
      }
    }
  }

  async onChildLoaded(component:  EditProfileComponent |
                      SeeArticlesComponent | OrdersComponent | ArticleWindowComponent | ArticlesComponent |
                      AcercaDeComponent | AtencionAlClienteComponent | EncuestaComponent) {
    this.component = undefined;
    if (this.listArticlesNavigation) {
      this.listArticlesNavigation = false;
      this.waitAddArticles = true;
      await this.addArticlesToShoppingCar();
      this.waitAddArticles = false;
    }
    if (this.router.url.includes('pago-correcto')) {
      this.numberArticles = 0;
      this.activateMobil = 3;
      this.notNeedArticles = true;
    } else if (this.router.url.includes('direcciones/nuevaDireccion')) {
      this.activateMobil = 2;
      this.notNeedArticles = true;
    }

    if (component instanceof SeeArticlesComponent) {
      this.activateMobil = 2;
      this.notNeedArticles = true;
      component.userInfo = this.userInfo;
      component.defaultRoute = this.defaultRoute;
      component.selectedOrderSuperGroup = this.selectedOrderSuperGroup;
      component.companyConf = this.companyConf;
      component.processOrderNav = this.processOrderNav;
      if (this.priceRoute != undefined) {
        component.priceRoute = this.priceRoute;
      }
      component.selectKgs = this.selectKgs;
      component.selectUnits = this.selectUnits;
      component.indicateLumps = this.companyConf.indicateLumps;
      component.newUser = this.newUser;
      component.homeDelivery = this.homeDelivery;
      component.superGroups = this.superGroups;

      component.numberArticles = this.newArticleNumber;
      component.newPostalCode = this.newPostalCode;
      component.getUser = this.getUser;
      component.addLocalStorageArticles = this.addLocalStorageArticles;
      component.setHomeDelivery = this.setHomeDelivery;
      component.initValues();

      component.minPrice = this.minPrice;
      component.storePickup = !this.homeDelivery;
      if (this.loadCountries) {
        component.countries = this.countries;
        component.codigoPostal = '28001';
        component.recalcularPrecioEntrega();

        if (component.homeDelivery) {
          component.codigoPostal = this.authStorageService.getCp();
          component.recalcularPrecioEntrega();
        }
      } else {
        component.codigoPostal = '28001';
        this.component = component;
      }

    } else if (component instanceof OrdersComponent) {
      this.activateMobil = 2;
      this.notNeedArticles = true;
      this.notNeedArticles = false;
      if (this.state != undefined) {
        this.startTopArticles = this.router.getCurrentNavigation().extras.state.topArticle;
      } else {
        this.startTopArticles = false;
      }

      component.articles = this.articles;
      component.companyConf = this.companyConf;
      component.inputProductsArticles = this.productArticles;
      component.paymentMethod = this.paymentMethod;
      component.loadingNewArticles = this.loadingNewArticles;
      component.selectedOrderSuperGroup = this.selectedOrderSuperGroup;

      component.numberArticles = this.setArticleNumber;
    } else if (component instanceof ArticleWindowComponent) {
      component.minPrice = this.minPrice;
      component.isUser = this.isUser;
      component.paymentMethod = this.paymentMethod;
      component.priceRoute = this.priceRoute;
      component.homeDelivery = this.homeDelivery;
      component.numberArticles = this.newArticleNumber;
    } else if (component instanceof ArticlesComponent) {
      component.minPrice = this.minPrice;
      component.priceRoute = this.priceRoute;
      component.paymentMethod = this.paymentMethod;
      component.homeDelivery = this.homeDelivery;
      component.selectKgs = this.selectKgs;
      component.selectUnits = this.selectUnits;

      component.numberArticles = this.newArticleNumber;
      component.numberOfArticles = this.numberArticles;
      this.component = component;
    }
  }



  inicializarInfo() {
    this.localStorageService.setShowShoppingCar(this.companyConf.showShoppingCar.toString());
    this.companyConf.logo = this.sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + this.companyConf.logo);
    if (this.companyConf.storePickup) {
      this.homeDelivery = !this.authStorageService.storePickup();
    } else {
      this.homeDelivery = true;
    }

    if (this.companyConf.selectQuantity) {
      this.calculateSelect();
    }

    if (!this.isUser) {
      this.cpNumber = this.authStorageService.getCp();
      if (this.authStorageService.getRoutePrices() != undefined) {
        this.priceRoute = this.authStorageService.getRoutePrices();
      }

      this.paymentMethod = 'CA';
      this.numberOfArticlesLocal();
    } else {
      this.getUser();
    }
  }

  getUser = () => {
    this.authService.getUser().subscribe(
      (result: UserInfo) => {
        this.authStorageService.setDiscount(result.descuento);
        this.authStorageService.setPendientePago(result.pendientePago);
        this.userInfo = result;
        this.priceRoute = result.priceRoute;
        this.authStorageService.setRoutePrices(this.priceRoute);
        if (!this.homeDelivery) {
          this.setInfoRoute();
        }
        this.paymentMethod = result.paymentMethod;

        this.isUser = true;
        this.newUser = true;
        this._cdr.detectChanges();
      }
    );
  }

  addLocalStorageArticles = () => {
    const articles = this.localStorageService.getLocalStorageArticles();
    let arts = [];

    if (articles != null && Array.isArray(articles)) {
      articles.forEach(el => {
        arts.push(new BasicArticle(el.id, el.format, el.quantity, el.lumps, el.cutType, el.dropDownGauge));
      })
    } else if (articles != null) {
      arts.push(new BasicArticle(articles.id, articles.format, articles.quantity, articles.lumps, articles.cutType, articles.dropDownGauge));
    }

    this.shoppingCarService.deleteAllArticles().subscribe(
      () => {
        this.shoppingCarService.addArticles(arts).subscribe(
          () => {
            this.numberArticles = arts.length;

            if (this.component instanceof ArticlesComponent) {
              this.component.numberOfArticles = this.numberArticles;
            }
            this._cdr.detectChanges();
          }
        );
      }
    );
    // this.shoppingCarService.addArticles(articles).subscribe();
  }

  setHomeDelivery = (value: boolean) => {
    this.homeDelivery = value;
    this.setInfoRoute();
  }

  getAllCountries() {
    this.defaultService.getAllDirections().subscribe(
      (data: Array<Country>) => {
        this.countries = data;
        this.loadCountries = true;
        if (this.component instanceof SeeArticlesComponent) {
          this.component.countries = this.countries;

          if (this.component.homeDelivery) {
            this.component.codigoPostal = this.authStorageService.getCp();
            this.component.recalcularPrecioEntrega();
          }
        }

        this.asignarCodigoPostal();
      }
    );
  }


  checkUser() {
    this.isUser = this.authStorageService.getUser() != null;
  }

  logOut() {
    this.authStorageService.logOut();
    this.localStorageService.setSelectedOrderSuperGroup(null);
    this.userInfo = null;
    this.isUser = false;
  }

  asignarCodigoPostal() {
    if (this.companyConf != undefined) {
      const country = this.countries.find(e => e.id == 'ES');
      if (country != null && this.companyConf.codigoPostal != undefined) {
        const province = country.provinces.find(e => e.id == this.companyConf.codigoPostal.substr(0, 2));
        if (province != null) {
          const city = province.cities.find(e => e.id == this.companyConf.codigoPostal.substr(2, 3));
          if (city != null) {
            this.priceRoute = city.priceRoute;
            this.setCp();
            return;
          }
        }
      }
    }
  }

  setCp() {
    this.cpNumber = this.companyConf.codigoPostal;
    this.authStorageService.setCp(this.cpNumber);
    this.authStorageService.setRoutePrices(this.priceRoute);
  }

  getMinOrderPrice() {
    this.shoppingCarService.getMinOrderPrice().subscribe(
      (data: MinPrice) => {
        this.minPrice = data.price;

        if (this.component instanceof SeeArticlesComponent) {
          this.component.minPrice = this.minPrice;

          if (this.component.homeDelivery) {
            this.component.codigoPostal = this.authStorageService.getCp();
            this.component.recalcularPrecioEntrega();
          }
        }
      });
  }

  newPostalCode = () => {
    this.cpNumber = this.authStorageService.getCp();
    if (this.authStorageService.getRoutePrices() != undefined) {
      this.priceRoute = this.authStorageService.getRoutePrices();
    }
  }

  calculateSelect() {
    this.selectKgs.push(this.companyConf.limits.minKg);
    this.selectUnits.push(this.companyConf.limits.minUnit);
    if (this.companyConf.limits.minKg < this.companyConf.limits.maxKg) {
      let total = this.companyConf.limits.minKg;
      while (total <= this.companyConf.limits.maxKg) {
        total += this.companyConf.limits.intervalKg;
        if (total <= this.companyConf.limits.maxKg) {
          this.selectKgs.push(total);
        }
      }
    }

    if (this.companyConf.limits.minUnit < this.companyConf.limits.maxUnit) {
      let total = this.companyConf.limits.minUnit;
      while (total <= this.companyConf.limits.maxUnit) {
        total += 1;
        if (total <= this.companyConf.limits.maxUnit) {
          this.selectUnits.push(total);
        }
      }
    }
  }

  selectMenuActive(init: Element, car: Element, profile: Element) {
    if (this.activateMobil == 0) {
      init.className = 'active';
      car.className = null;
      profile.className = null;
    } else if (this.activateMobil == 1) {
      car.className = 'active';
      init.className = null;
      profile.className = null;
    } else if (this.activateMobil == 2 || this.activateMobil == 4) {
      profile.className = 'active';
      init.className = null;
      car.className = null;
    } else {
      init.className = null;
      car.className = null;
      profile.className = null;
    }
    this.activateMobil = null;
  }

  topPage() {
    // getWindow().scroll(0, 0);
  }

  newArticleNumber = (number: number) => {
    this.numberArticles = number;
    if (this.component instanceof ArticlesComponent) {
      this.component.numberOfArticles = this.numberArticles;
    }
  }

  numberOfArticlesLocal() {
    this.numberArticles = 0;
    if (this.component instanceof ArticlesComponent) {
      this.component.numberOfArticles = this.numberArticles;
    }
    const arts = this.localStorageService.getLocalStorageArticles();
    let articlesCookies: Array<CarArticle> = null;
    if (arts != null && Array.isArray(arts)) {
      articlesCookies = arts;
    } else if (arts != null) {
      articlesCookies = [arts];
    }

    if (articlesCookies != null) {
      let articlesStr = '';
      articlesCookies.forEach( e => {articlesStr += "'" + e.id + "'" + ','});
      articlesStr = articlesStr.substr(0, articlesStr.length - 1);
      if (articlesStr != '') {
        this.shoppingCarService.getPendingArticles(articlesStr, null).subscribe(
          (data: Array<CarArticle>) => {
            if (data != undefined) {
              this.numberArticles = data.length;
              if (this.component instanceof ArticlesComponent) {
                this.component.numberOfArticles = this.numberArticles;
              }
            }
          }
        );
      }
    }
  }

  setArticleNumber = () => {
    this.defaultService.getNumberOfArticles().subscribe(
      (result: NumberInterface) => {
        this.numberArticles = result.value;
        if (this.component instanceof ArticlesComponent) {
          this.component.numberOfArticles = this.numberArticles;
        }
      }
    );
  }

  async  addArticlesToShoppingCar() { // se añaden todos los articulos al carrito
    if (this.arrayListArticles == undefined || this.shoppingCarArticlesCp == undefined) {return;}
    const articles: Array<BasicArticle> = [];
    const articlesDelete: Array<DeleteArticle> = [];
    const articlesSetQuantity: Array<QuantityLumpsArticle> = [];
    let numOfArticles = 0;
    this.arrayListArticles.forEach( el => { // Filtramos los articulos
        el[1].forEach( art => {
          art.forEach( article => {
            if (article.cut == '') article.cut = null;
            this.shoppingCarArticlesCp.forEach(a => {if (a.cutType == '') article.cutType = null});

            const shArticle = this.shoppingCarArticlesCp.find(s => s.id == article.id && s.cutType == article.cut);
            if (article.quantity == 0 && article.lumps == null && article.format == '1' && this.companyConf.onlyUnits) {
              article.quantity = null;
            }

            if (article.quantity != null || (article.lumps != null && this.companyConf.onlyUnits)) {
              if (this.companyConf.onlyUnits && article.format == '1') {
                if (shArticle != null) { // Cambiar cantidad
                  if (shArticle.lumps != article.lumps) { // Cambiar cantidad
                    articlesSetQuantity.push(new QuantityLumpsArticle(article.id, article.format, shArticle.line, article.quantity, article.lumps));
                  }
                } else { // Añadir nuevo articulo
                  articles.push(new BasicArticle(article.id, article.format.toString(), article.quantity, article.lumps, article.cut, false));
                }
              } else if (this.companyConf.onlyUnits && article.format != '1') { // Vender en unidades
                if (shArticle != null) { // Cambiar cantidad
                  if (shArticle.lumps != article.lumps) {
                    articlesSetQuantity.push(new  QuantityLumpsArticle(article.id, article.format, shArticle.line, article.lumps, article.lumps));
                  }
                } else { // Añadir nuevo articulo
                  articles.push(new BasicArticle(article.id, article.format.toString(), article.lumps, article.lumps, article.cut, false));
                }
              } else {
                if (shArticle != null) { // Cambiar cantidad
                  if (shArticle.quantity != article.quantity || shArticle.format != article.format) {
                    articlesSetQuantity.push(new  QuantityLumpsArticle(article.id, article.format, shArticle.line, article.quantity, 0));
                  }
                } else { // Añadir nuevo articulo
                  const a = new BasicArticle(article.id, article.format.toString(), article.quantity, 0, article.cut, false);
                  a.Observations = article.observations;
                  a.AddQuantity = false;
                  articles.push(a);
                }
              }

              numOfArticles ++;
            } else if (shArticle != null) { // eliminar articulo
              if (shArticle.cutType == '') shArticle.cutType = null;
              articlesDelete.push(new DeleteArticle(shArticle.id, shArticle.line.toString(), shArticle.cutType));
            }
          });
        });
      }
    );

    if (articlesDelete.length > 0) {
      await this.deletePendingArticles(articlesDelete).then();
    }

    if (articles.length > 0) {
      await this.addArticles(articles).then();
    }

    if (articlesSetQuantity.length > 0) {
      await this.setListArticlesQuantity(articlesSetQuantity).then();
    }

    this.numberArticles = numOfArticles;
    if (this.component instanceof ArticlesComponent) {
      this.component.numberOfArticles = this.numberArticles;
    }
    this.arrayListArticles = null;
  }

  async addArticles(articles) {
    return await this.shoppingCarService.addArticles(articles).toPromise();
  }

  async deletePendingArticles(articles) {
    return await this.shoppingCarService.deletePendingArticles(articles).toPromise();
  }

  async setListArticlesQuantity(articles) {
    return await this.shoppingCarService.setListArticlesQuantity(articles).toPromise();
  }

  setInfoRoute() {
    if (this.homeDelivery) {
      this.authStorageService.setInfoRoute();
      if (this.authStorageService.getRoutePrices() != undefined) {
        this.priceRoute = this.authStorageService.getRoutePrices();
      }
    } else {
      this.authStorageService.copyInfoRoute();
      this.priceRoute = new PriceRoute(0, 0, 0, 0);
    }
  }

  @HostListener('window:beforeunload')
  addArticlesList() {
    if (this.listArticlesNavigation) {
      this.addArticlesToShoppingCar();
    }
  }


  @HostListener('focusin', ['$event'])
  onFocus($event) {
    const target = $event.target;
    if (target.tagName == "INPUT" && target.type == "text") {
      this.showBottomMenu = false;
    }
  }

  @HostListener('focusout', ['$event'])
  onFocusOut() {
    this.showBottomMenu = true;
  }

  openExternalLink(link: string) {
    getWindow().open(link, '_blank', 'location=yes');
  }

  // _________________________ REDIRECCIONAR _____________________________
  redirectToGrupo(nombre: string) {
    return '/compra/' + nombre.toLowerCase().split(' ').join('-').split('(').join('').split(')').join('').split('/').join('');
  }

  toggleNavbar() {
    this.isNavbarCollapsed = !this.isNavbarCollapsed;
  }


  inicializarFamilias() {
    this.loadedInitialValues = true;

    // Mostrar super grupo a seleccionar
    this.selectedOrderSuperGroup = this.localStorageService.getSelectedOrderSuperGroup();
    if (this.isUser) {
      this.defaultService.getNumberOfArticles().subscribe(
          (result: NumberInterface) => {
            this.numberArticles = result.value;
            if (this.component instanceof ArticlesComponent) {
              this.component.numberOfArticles = this.numberArticles;
            }
            this._cdr.detectChanges();
          }
      );
    }
  }

  /*_____FUNCIONES REDUX---------*/
  obtenerDatosEmpresa() {
    this.storeEmpresa.select('empresaInfo').subscribe(
        (value: any) => {
          if (value != undefined && !value.loading && value.value != null) {
            this.datosLegalesEmpresa = value.value;
          } else {
            this.defaultService.getCompanyInfo().subscribe((value: any) => {
              this.storeEmpresa.dispatch(obtenerEmpresaInfo({ value }));
              this.datosLegalesEmpresa = value;
            });
          }
        });

    this.storeEmpresa.select('empresaInfoTotal').subscribe(
        (value: any) => {
          if (value != undefined && !value.loading && value.value != null) {
            this.companyConf = value.value;
            this.checkUser();
            this.inicializarInfo();
          } else {
            this.defaultService.getCompanyConfig().subscribe((value: any) => {
              this.storeEmpresa.dispatch(obtenerEmpresaInfoTotal({ value }));
              this.companyConf = value;
              this.checkUser();
              this.inicializarInfo();
            });
          }
        });
  }

  obtenerPaises() {
    this.storeEmpresa.select('paisesInfo').subscribe(
        (value: any) => {
          if (value != undefined && !value.loading && value.value != null) {
            this.countries = value.value;
          } else {
            this.defaultService.getAllDirections().subscribe((value: any) => {
              this.storeEmpresa.dispatch(obtenerPaises({ value }));
              this.countries = value;
            });
          }
        });
  }


  obtenerSuperGrupos() {
    this.storeEmpresa.select('superGruposInfo').subscribe(
        (value: any) => {
          if (value != undefined && !value.loading && value.value != null) {
            this.superGroups = value.value;
            this.showOffers = this.superGroups.find(el => el.groups.find(g => g.id == 'of') != null) != null;
          } else {
            this.defaultService.getSuperGroups().subscribe((value: any) => {
              this.superGroups = value;
              this.showOffers = this.superGroups.find(el => el.groups.find(g => g.id == 'of') != null) != null;
              this.storeEmpresa.dispatch(obtenerSuperGrupo({ value }));
            });
          }
        });
  }

  obtenerFamilias() {
    this.storeEmpresa.select('familiasReducer').subscribe(
        (value: any) => {
          if (value != undefined && !value.loading && value.value != null) {
            this.families = value.value;
            this.inicializarFamilias();
          } else {
            this.defaultService.getSuperGroups().subscribe((value: any) => {
              this.families = value;
              this.inicializarFamilias();
              this.storeEmpresa.dispatch(obtenerFamilias({ value }));
            });
          }
        });
  }

}
