import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Article, BasicArticleQuantity} from '../../../interfaces/article';
import {SuperGroup} from '../../../interfaces/group';
import {Family} from '../../../interfaces/family';
import {CompanyConf} from '../../../interfaces/company-conf';
import {PriceRoute} from "../../../interfaces/price-route";
import {obtenerArticulosInfo, obtenerEmpresaInfoTotal, obtenerFamilias, obtenerSuperGrupo} from '../../../../redux/empresa.actions';
import {Store} from '@ngrx/store';
import {DefaultService} from '../../../services/default.service';
import {CarArticle} from '../../../interfaces/car-article';
import {ShoppingCarService} from '../../../services/shopping-car.service';
import {LocalStorageService} from '../../../services/local-storage.service';
import {cloneDeep} from 'lodash';
import {Meta, Title} from '@angular/platform-browser';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-article-window',
  templateUrl: './article-window.component.html',
  styleUrls: ['./article-window.component.scss']
})
export class ArticleWindowComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute, private titleService: Title,
              private metaService: Meta, @Inject(DOCUMENT) private _document: Document,
              private storeEmpresa: Store<any>, private defaultService: DefaultService,
              private shoppingCarService: ShoppingCarService, private localStorage: LocalStorageService) {
    this.route.queryParams.subscribe(params => {
      this.groupId = params['grupo'];
      this.familyId = params['familia'];
      this.articleId = params['id'];

      router.events.subscribe((val) => {
        if (this.urlActual != this.router.url) {
          this.urlActual = this.router.url;

          this.route.queryParams.subscribe(params => {
            this.groupId = params['grupo'];
            this.familyId = params['familia'];
            this.articleId = params['id'];
          });

          this.articles = [];
          this.obtenerArticulos();
          this.newNavigationFn();
        }
      });
    });
  }

  ngOnInit(): void {
      this.obtenerDatosEmpresa();
      this.obtenerSuperGrupos();
      this.obtenerFamilias();
  }

  urlActual = "";
  groupName: string;
  groupId: string;
  familyName: string;
  familyId: string;
  article: Article;
  articleId: string;
  superGroup: SuperGroup;
  navigationRoute: any;
  homeDelivery: boolean;
  families: Array<Family>;
  superGroups: Array<SuperGroup>;
  minPrice: number;
  paymentMethod: string;
  companyConf: CompanyConf;
  priceRoute: PriceRoute;
  isUser: boolean;
  indicateLumps: boolean;
  numberArticles: Function;

  navigationRouteCopy = null;
  articles: Array<Article>;

  dropDownGauge = false;
  variasFotos = false;

  newNavigationFn(){
    this.variasFotos = false;
    this.navigationRouteCopy = this.navigationRoute;
    this.navigationRoute = null;
    this.getArticle();

    if (this.article != null) {
      this.groupName = this.article.groupName;
      this.familyName = this.article.familyName;
    }

    this.superGroups.forEach( e => e.groups.find(el => {
      if (el.id == this.groupId && e.id != '') {
        this.superGroup = e;
      }
    }));


  }


  newArticleNumber(number){
    this.numberArticles(number);
  }

  getArticle() {
    if (this.articleId != undefined) {
      this.article = this.articles.find(e => e.id == this.articleId);
      this.cargarPosicionamientoArticulo();
      this.checkIfImageExists('assets/fotos/articulos/' + this.unirCadenasDespuesDelUltimoPunto(cloneDeep(this.article.articleImgUrl), '_1' ));
    }
  }

  cargarPosicionamientoArticulo() {
    this.limpiarPosicionamiento();
    this.titleService.setTitle('Comprar online ' + this.article.name + ' | ' + this.companyConf.webName);

    if (this.article.procedimientos != undefined && this.article.procedimientos != null) {
      this.metaService.addTag({
        name: 'description',
        content: this.article.procedimientos
      });

      this.metaService.addTag({name: 'og:description', content: this.article.procedimientos});
    } else {
      this.metaService.addTag({
        name: 'description',
        content: 'Comprar Online y al mejor precio ' + this.article.name + '. Productos frescos, el ' + this.article.name + ' de la mejor calidad del mercado. Servicio en 48h. De la tienda a tu mesa.' + this.companyConf.webName + ' y deleita tu paladar hoy mismo!'
      });
      this.metaService.addTag({name: 'og:description', content: 'Comprar Online y al mejor precio ' + this.article.name + '. Productos frescos, el ' + this.article.name + ' de la mejor calidad del mercado. Servicio en 48h. De la tienda a tu mesa.' + this.companyConf.webName + ' y deleita tu paladar hoy mismo!'});
    }
    this.metaService.addTag({name: 'title', content: 'Comprar online ' + this.article.name + ' | ' + this.companyConf.webName});
    this.metaService.addTag({name: 'og:title', content: 'Comprar online ' + this.article.name + ' | ' + this.companyConf.webName});
    this.metaService.addTag({name: 'og:type', content: 'website'});
    this.metaService.addTag({name: 'product:price:currency', content: 'EUR'});
    this.metaService.addTag({name: 'product:price:amount', content: this.article.priceIva.toString()});
    this.metaService.addTag({name: 'product:availability', content: 'availability'});
    this.metaService.addTag({name: 'priceCurrency', content: '€'});

    this.metaService.addTag({
      name: 'og:image',
      itemprop: 'image',
      content: this._document.URL.replace(this.urlActual, '').replace(/[?&][^=?&]+=[^=?&]+/g, '')+ '/assets/fotos/articulos/size-xs/' + this.article.articleImgUrl}
    );
    this.metaService.addTag({
      name: 'keywords',
      content: this.article.name + ',E-commerce,Comprar Online,' + this.companyConf.webName + ',' + this.article.groupName + ',comprar,producto,kilos,cajas,unidades,mallas,docenas'
    });
    this.metaService.addTag({name: 'og:image:type', content: "image/webp"});
    this.metaService.addTag({name: 'og:image:width', content: "250"});
    this.metaService.addTag({name: 'og:image:height', content: "250"});
  }

  limpiarPosicionamiento() {
    this.metaService.removeTag("name='title'");
    this.metaService.removeTag("name='og:title'");
    this.metaService.removeTag("name='description'");
    this.metaService.removeTag("name='og:site_name'");
    this.metaService.removeTag("name='og:url'");
    this.metaService.removeTag("name='name'");
    this.metaService.removeTag("name='author'");
    this.metaService.removeTag("name='keywords'");
    this.metaService.removeTag("name='robots'");
    this.metaService.removeTag("name='og:locale'");
    this.metaService.removeTag("name='og:type'");
    this.metaService.removeTag("name='og:image'");
    this.metaService.removeTag("name='og:image:type'");
    this.metaService.removeTag("name='og:image:width'");
    this.metaService.removeTag("name='og:image:height'");
    this.metaService.removeTag("name='og:description'");
    this.metaService.removeTag("name='product:price:currency'");
    this.metaService.removeTag("name='product:price:amount'");
    this.metaService.removeTag("name='product:availability'");
  }

  unirCadenasDespuesDelUltimoPunto(cadena1: string, cadena2: string): string {
    const ultimoPuntoIndex = cadena1.lastIndexOf('.');

    if (ultimoPuntoIndex != -1) {
      return cadena1.substring(0, ultimoPuntoIndex)  + cadena2 + cadena1.substring(ultimoPuntoIndex);
    } else {
      return cadena1 + cadena2;
    }
  }

  checkIfImageExists(url: string) {
    const img = new Image();
    img.src = url;

    if (img.complete) {
      this.variasFotos =  true;
    } else {
      img.onload = () => {
        this.variasFotos =  true;
      };
    }
  }

  obtenerInfoInicialArticulos(data: any) {
    if (this.isUser) {
      this.shoppingCarService.getPendingArticles(null, null).subscribe(
          (data1: Array<CarArticle>) => {
            let quantities: Array<BasicArticleQuantity> = [];
            data1.forEach(el => {
              quantities.push({id: el.id, line: el.line, quantity: el.quantity})
            });

            if (quantities.length > 0) {
              if (data != undefined) {
                data.forEach(el =>  {
                  let art = quantities.find(a => a.id == el.id);
                  if (art != undefined) {
                    if(el.id == art.id) {
                      el.quantity = art.quantity;
                      el.line = art.line;
                    }
                  }
                });
              }
            }
          }
      );
    } else {
      const arts = this.localStorage.getLocalStorageArticles();
      if (arts != undefined) {
        data.forEach(el =>  {
          let art = undefined;
          if (arts instanceof Array) {
            art = arts.find(a => a.id == el.id);
          } else {
            art = arts;
          }

          if (art != undefined) {
            if(el.id == art.id) {
              el.quantity = parseFloat(art.quantity);
            }
          }
        });
      }

      data = data.filter(el => el.family == this.familyId && el.group == this.groupId);

    }


    this.urlActual = this.router.url;
    this.articles = data;
    this.newNavigationFn();
  }



  // _________________________ REDIRECCIONAR _____________________________
  redirectToGrupo(nombre: string) {
    return '/compra/' + nombre.toLowerCase().split(' ').join('-').split('(').join('').split(')').join('').split('/').join('');
  }


  /*_____FUNCIONES REDUX---------*/
  obtenerDatosEmpresa() {
    this.storeEmpresa.select('empresaInfoTotal').subscribe(
        (value: any) => {
          if (value != undefined && !value.loading && value.value != null) {
            this.companyConf = value.value;
            this.indicateLumps = this.companyConf.indicateLumps;
            this.obtenerArticulos();
          } else {
            this.defaultService.getCompanyConfig().subscribe((value: any) => {
              this.storeEmpresa.dispatch(obtenerEmpresaInfoTotal({ value }));
              this.companyConf = value;
              this.indicateLumps = this.companyConf.indicateLumps;
              this.obtenerArticulos();
            });
          }
        });
  }

  obtenerArticulos() {
    this.storeEmpresa.select('articulosInfo').subscribe(
        (value: any) => {
          if (value != undefined) {
            this.obtenerInfoInicialArticulos(value);
          } else {
            this.defaultService.getNewArticles('ES', null, null, false, true).subscribe(
                (value: Array<Article>) => {
                  this.storeEmpresa.dispatch(obtenerArticulosInfo({ value }));
                  this.obtenerInfoInicialArticulos(value);
                });
          }
        });
  }



  obtenerSuperGrupos() {
    this.storeEmpresa.select('superGruposInfo').subscribe(
        (value: any) => {
          if (value != undefined && !value.loading && value.value != null) {
            this.superGroups = value.value;
          } else {
            this.defaultService.getSuperGroups().subscribe((value: any) => {
              this.superGroups = value;
              this.storeEmpresa.dispatch(obtenerSuperGrupo({ value }));
            });
          }
        });
  }

  obtenerFamilias() {
    this.storeEmpresa.select('familiasReducer').subscribe(
        (value: any) => {
          if (value != undefined && !value.loading && value.value != null) {
            this.families = value.value;
          } else {
            this.defaultService.getSuperGroups().subscribe((value: any) => {
              this.families = value;
              this.storeEmpresa.dispatch(obtenerFamilias({ value }));
            });
          }
        });
  }
}
