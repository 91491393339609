import {Component, HostListener, Input, ViewChild} from '@angular/core';
import {Group, SuperGroup} from '../../../interfaces/group';

import {getWindow} from "ssr-window";
@Component({
  selector: 'app-carousel-groups',
  templateUrl: './carousel-groups.component.html',
  styleUrls: ['./carousel-groups.component.scss']
})
export class CarouselGroupsComponent {

  @Input() superGroups: Array<SuperGroup>;
  @ViewChild('left') left: any;
  @ViewChild('myname') input: any;
  innerWidth = getWindow().innerWidth;
  @Input() companyException = "";

  getArticlesGroup(group: Group): string {
    if (group.id.toLowerCase().trim() == 'of') {
      return'/compra/ofertas';
    } else {
      return '/compra/' + group.name.toLowerCase().split(' ').join('-').split('(').join('').split(')').join('').split('/').join('');
    }
  }


  @HostListener('window:resize')
  onResize() {
    this.innerWidth = getWindow().innerWidth;
  }
}
