<p class="text-dark mb-3 mt-4 font-weight-bold" style="font-size: 1.5rem; line-height: normal">A otros clientes también les gustó</p>
<div class="scrolling-wrapper" #CONTENT>
  <div class="pointer h-100 me-4" *ngFor="let article of articles">
    <a class="text-decoration-none text-dark"
       [routerLink]="getNewArticle()" [queryParams]="{grupo: article.group, familia: article.family, id: article.id  }">
      <div class="mb-2 h-100" style="width: 250px">
        <img *ngIf="article.articleImgUrl" width="100%" height="auto"
             src="assets/fotos/articulos/size-s/{{article.articleImgUrl}}"
             title="Foto de {{article.name}}"
             alt="Foto de {{article.name}}"/>

        <h2 class="text-dark article-name"  #myDivs>{{article.name}}</h2>
        <p style="font-size: 1.25rem; line-height: normal" >
          {{article.priceIvaStr | currency: 'EUR'}}
          <span *ngIf="article.format == '1'">/Kg</span>
          <span *ngIf="article.format != '1'">/ud.</span>
        </p>
      </div>
    </a>
  </div>
</div>
