<nav class="max d-flex justify-content-center align-items-center mt-2">
  <div class="center" style="min-height: 35px">
    <a class="products btn" (click)="redireccionarBuscar()">BUSCAR</a>
    <a class="products btn" routerLink="/compra/ofertas" *ngIf="showOffers">OFERTAS</a>

    <div class="d-flex">
      <div class="mb-0 ms-0 ms-0 me-0" id="container-list" *ngFor="let superGroup of superGroups; let g = index">
        <div *ngIf="superGroup.id != '' && superGroup.id != 'none' && superGroup.groups.length > 1; else elseGroup"
             class="dropdown pt-1">
          <a class="text-decoration-none text-dark dropdown-toggle products me-3 mx-3" role="button"
             [id]="'dropdownMenuLink' + g"  data-bs-toggle="dropdown"  aria-expanded="false">
            {{superGroup.name}}
          </a >

          <ul class="dropdown-menu" [attr.aria-labelledby]="'dropdownMenuLink' + g">
            <li>
              <a class="dropdown-item products btn" [queryParams]="{superGrupo: superGroup.id, grupo: '*'}"
                 [routerLink]="getArticlesSuperGroupAll(superGroup)">
                VER TODO
              </a>
            </li>
            <li *ngFor="let group of superGroup.groups">
              <a class="dropdown-item products btn"
                 [routerLink]="getArticlesSuperGroupGroup(group)"
                 [queryParams]="{superGrupo: superGroup.id, grupo: group.id}">
                {{group.name}}
              </a>
            </li>
          </ul>
        </div>

        <ng-template #elseNameGroup>
          {{superGroup.name}}
        </ng-template>

        <ng-template #elseGroup>
          <div class="d-flex">
            <div *ngFor="let group of superGroup.groups">
              <a class="ms-0  me-0 products btn btn-default text-decoration-none text-dark" id="{{group.id}}"
                 [routerLink]="getArticles(group)"
                 [queryParams]="{superGrupo: 'none', grupo: group.id}">
                {{group.name}}
              </a>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</nav>
