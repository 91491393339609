import { Injectable } from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable, throwError} from 'rxjs';
import {AuthStorageService} from './auth-storage.service';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(private authStorageService: AuthStorageService, private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  let request = req;
  const jwtToken = this.authStorageService.getUser();

  if (jwtToken) {
  request = req.clone({
    setHeaders: {
    authorization: `${jwtToken}`,
    }
  });
  }

  return next.handle(request).pipe(
  catchError((err: HttpErrorResponse) => {
    if (err.status == 401) {
    this.router.navigateByUrl('/iniciarSesion');
    }
    return throwError( err );
  })
  );
  }
}
