<div *ngIf="articles.length > 0 && isBrowser; else loading">
  <div class="container-fluid mt-3 p-5-computer">
    <div class="row">
      <div class="col-12">
        <div class="navigation_page mb-3 d-flex align-items-center">
          <i class="fas fa-home me-1"></i>
          <div class="navigation-pipe me-1" *ngIf="!offers"> » </div>
          <div *ngIf="superGroup  && !searchWindow && !offers && superGroupId != 'none' && superGroup.groups.length > 1;">
            <div class="d-flex align-items-center">
              <a class="me-1" *ngIf="groupId != '*' && superGroup.id != '' && superGroup.groups.length > 1" itemprop="url"
                 [routerLink]="redirectToGrupo(superGroup.name)" [queryParams]="{ superGrupo: superGroupId, grupo: '*' }">
                <h1 itemprop="title">{{superGroup.name}}</h1>
              </a>

              <h1 itemprop="title" *ngIf="groupId == '*' || superGroup.groups.length == 1">{{superGroup.name}}</h1>
              <span *ngIf="groupId != '*'  && superGroup.groups.length > 1" class="navigation-pipe me-1"> » </span>
            </div>
          </div>

          <div *ngIf="(groupId != '*' && !searchWindow && !offers) || superGroupId == 'none'; else elseMenu1">
            <h1 itemprop="title">{{groupName}}</h1>
          </div>

          <div *ngIf="superGroup && !searchWindow && !offers">
            <div *ngIf="superGroupId != 'none' && groupId == '*' && superGroup.groups.length == 1; else elseMenu1">
              <h1 itemprop="title">{{superGroup.groups[0].name}}</h1>
            </div>
          </div>

          <ng-template #elseMenu1>
            <h1 class="navigation-pipe me-1" *ngIf="offers"> » PROMOCIONES</h1>
            <h1 class="navigation-pipe me-1" *ngIf="searchWindow"> BUSCAR</h1>
          </ng-template>
        </div>
      </div>
    </div>

    <div class="sticky-top w-100 bg-white border-bottom" >
      <app-carousel-groups [superGroups]="superGroups" [companyException]="companyConf.companyException"></app-carousel-groups>
    </div>

    <div class="row">
      <div class="col-md-3 col-12 mt-3"
           *ngIf="companyConf.companyException != 'ENJA' && companyConf.companyException != 'CAES' && companyConf.companyException != 'IVAN'"
           id="index">
        <!-- FILTRO ORDENADOR -->
        <div class="filter-computer">
          <div class="card">
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <div class="card-title text-dark">FILTRAR</div>
              </li>

              <li class="list-group-item" *ngIf="!searchWindow && !offers && familiesOccult">
                <div class="d-flex" (click)="showFamiliesFn()">
                  <div style="font-size: 1.25em">PRODUCTOS</div>
                  <div class="drop-down-icon d-flex justify-content-end">
                    <i class="fas fa-caret-down"></i>
                  </div>
                </div>
                <div class="listContent" *ngIf="showFamilies">
                  <ul class="list-group list-group-flush container-family">
                    <div *ngFor="let family of families">
                      <div class="content-filter">
                        <label>
                          <input type="checkbox" [value]="family.id" [defaultChecked]="familySelected(family.id)"
                                 (change)="filterFamilyFn($event)">
                          {{family.name}}
                        </label>
                      </div>
                    </div>
                  </ul>
                </div>
              </li>

              <li class="list-group-item">
                <div class="d-flex" (click)="showFormatsFn()">
                  <div style="font-size: 1.25em">FORMATO</div>
                  <div class="drop-down-icon d-flex justify-content-end">
                    <i class="fas fa-caret-down"></i>
                  </div>
                </div>
                <div class="listContent" *ngIf="showFormats">
                  <ul class="list-group list-group-flush container-family">
                    <div class="content-filter" *ngFor="let format of formatNames">
                      <label>
                        <input type="checkbox" [value]="format" [defaultChecked]="formatSelected(format)"
                               (change)="filterFormatFn($event)">
                        {{format}}
                      </label>
                    </div>
                  </ul>
                </div>
              </li>

              <li class="list-group-item" *ngIf="!offers && showOffersFilter">
                <div class="d-flex">
                  <div style="font-size: 1.25em">
                    <label>
                      <input type="checkbox" (change)="filterOfferFn()">
                      PROMOCIONES
                    </label>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

      </div>

      <div class="col-12 mt-3" [ngClass]="{'col-md-9': companyConf.companyException != 'ENJA' && companyConf.companyException != 'CAES'&& companyConf.companyException != 'IVAN'}">
        <!-- FILTRO MOBIL-->
        <div class="card filter-mobil w-100"   *ngIf="companyConf.companyException != 'ENJA' && companyConf.companyException != 'CAES' && companyConf.companyException != 'IVAN'">
          <ul class="list-group list-group-flush">
            <li class="d-flex align-items-center" id="filter-mobil-title" (click)="showFilterMobilFn()">
              <i class="fas fa-filter" style="margin: 10px 15px auto 10px"></i>
              <div class="card-title text-dark font-weight-bold" id="card-title-mobil">FILTRAR</div>
              <div class="drop-down-icon d-flex justify-content-end">
                <i class="fas fa-caret-down" style="margin: 8px 15px 8px auto"></i>
              </div>
            </li>

            <div *ngIf="showFilterMobil">
              <li class="list-group-item" *ngIf="!searchWindow && !offers">
                <div class="d-flex" (click)="showFamiliesFn()">
                  <div style="font-size: 1.25em">PRODUCTOS</div>
                  <div class="drop-down-icon d-flex justify-content-end">
                    <i class="fas fa-caret-down"></i>
                  </div>
                </div>
                <div class="listContent" *ngIf="showFamilies">
                  <ul class="list-group list-group-flush">
                    <div *ngFor="let family of inputFamilies">
                      <div class="content-filter" *ngIf="family.group == this.groupId">
                        <label>
                          <input class="form-check-input" type="checkbox" [value]="family.id"
                                 [defaultChecked]="familySelected(family.id)" (change)="filterFamilyFn($event)">
                          {{family.name}}
                        </label>
                      </div>
                    </div>
                  </ul>
                </div>
              </li>

              <li class="list-group-item">
                <div class="d-flex" (click)="showFormatsFn()">
                  <div style="font-size: 1.25em">FORMATO</div>
                  <div class="drop-down-icon d-flex justify-content-end">
                    <i class="fas fa-caret-down"></i>
                  </div>
                </div>
                <div class="listContent" *ngIf="showFormats">
                  <ul class="list-group list-group-flush">
                    <li class="content-filter" *ngFor="let format of formatNames">
                      <label>
                        <input class="form-check-input" type="checkbox" [value]="format"
                               [defaultChecked]="formatSelected(format)" (change)="filterFormatFn($event)">
                        {{format}}
                      </label>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="list-group-item" *ngIf="!offers && showOffersFilter">
                <div class="d-flex">
                  <div style="font-size: 1.25em">
                    <label>
                      <input type="checkbox" (change)="filterOfferFn()">
                      PROMOCIONES
                    </label>
                  </div>
                </div>
              </li>
            </div>
          </ul>
        </div >

        <!-- ORDENAR Y BUSCAR-->
        <div class="row mt-4">
          <div class="col-md-5">
            <div class="select">
              <select class="selectProductSort form-control" (change)="orderArticles($event.target)">
                <option value="name:asc" selected="selected">Nombre, de A a Z</option>
                <option value="name:desc">Nombre, de Z a A</option>
                <option value="price:asc">Precio, más baratos primero</option>
                <option value="price:desc">Precio, más caros primero</option>
              </select>
            </div>
          </div>

        </div>

        <!-- PAGINACIÓN ARRIBA-->
        <div class="d-flex justify-content-end mt-4" *ngIf="articles.length > 40 && companyConf.topPagination">
          <pagination-controls class="pagination justify-content-end font-weight-bold" previousLabel="Anterior"
                               nextLabel="Siguiente" style="font-size: 12px" (pageChange)="pageChanged($event)"></pagination-controls>

        </div>


        <!-- botão flutuante whatsapp -->
        <a class="fixed-element bg-secondary whatsapp-computer p-3 rounded-5 text-decoration-none"
           style="font-size: 15px !important; height: 60px !important;" [routerLink]="'/carrito'" title="Carrito">
          <div class="d-flex align-items-center ">
            <i class="fas fa-shopping-cart fa-2x text-white"></i><span class='badge badge-warning lblCartCount'>{{numberOfArticles}} </span>
          </div>
        </a>

        <div class="row">
          <div  *ngFor="let article of articles  | paginate: {itemsPerPage: 40, currentPage: pageArticles}; let pos = index"
              class="col-6 mt-3 p-3"
                [ngClass]="{'col-md-3': !verTresArticulosOrdenador && companyConf.companyException != 'ENJA',
                            'col-md-4': verTresArticulosOrdenador || companyConf.companyException == 'ENJA'}">
            <a class="text-decoration-none text-black h-100"
               [routerLink]="redirectToArticulo(article.groupName, article.name)"
               [queryParams]="{ grupo: article.group, familia: article.family, id: article.id }">
              <a *ngIf="article.articleImgUrl || article.productImgUrl"class="mb-2 text-decoration-none">
                <div class="w-100 d-flex justify-content-center">
                  <div *ngIf="companyConf.companyException == 'CHEF' && article.articleImgUrl; else imagenesArt"
                       class="grid-bg-img mt-2">
                    <div *ngIf="article.quantity > 0 && companyConf.companyException != 'ENJA'" class="w-100 h-100 text-center font-cantidad"
                         style="margin-top: 35%">
                      <h1 style="font-size: 2.5em !important;">
                          <span *ngIf="article.format == '1'">
                            {{article.quantity | number: '1.2'}} Kg</span>
                        <span *ngIf="article.format != '1'">
                            {{article.quantity}} Ud</span>
                      </h1>
                    </div>
                  </div>

                  <ng-template #imagenesArt >
                    <div [id]="'carouselExample' + article.id" class="carousel slide pointer w-100">
                      <div class="carousel-indicators" *ngIf="article.search">
                        <button (click)="stopPropagation($event)" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0"
                                class="active" aria-current="true" aria-label="Slide 1"></button>
                        <button (click)="stopPropagation($event)" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"
                                aria-label="Slide 2"></button>
                      </div>

                      <div class="carousel-inner w-100" >
                        <div class="carousel-item active w-100">
                          <img (mouseenter)="setCarrouselImgNext(article.search,  article.id)"
                               [src]="'assets/fotos/articulos/size-s/' + urlImg(0, article.articleImgUrl).split(' ').join('%20')"
                               [alt]="'Imágen de ' + article.name" [name]="article.name"
                               width="100%" height="auto">
                        </div>
                        <div class="carousel-item" *ngIf="article.search">

                          <img (mouseenter)="setCarrouselImgNext(article.search,  article.id)"
                               [src]="'assets/fotos/articulos/size-s/' + urlImg(1, article.articleImgUrl).split(' ').join('%20')"
                               [alt]="'Segunda imágen de ' + article.name" [name]="article.name"
                               width="100%" height="auto">
                        </div>
                      </div>

                      <button *ngIf="article.search" class="carousel-control-prev" type="button"
                              [id]="'buttonNext' + article.id"
                              (click)="stopPropagation($event)"
                              [attr.data-bs-target]="'#carouselExample' + article.id" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                      </button>
                      <button *ngIf="article.search" class="carousel-control-next" type="button"
                              [id]="'buttonPrev' + article.id"
                              (click)="stopPropagation($event)"
                              [attr.data-bs-target]="'#carouselExample' + article.id"
                              data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                      </button>
                    </div>
                  </ng-template>
                </div>
              </a>

              <div class="d-flex justify-content-center align-items-center w-100 pb-0"
                   *ngIf="!(article.articleImgUrl || article.productImgUrl)" style="height: 1.875em !important;">
                <span class="text-info font-weight-bold" *ngIf="article.offer == 1">
                  ¡Oferta!
                </span>
                <span class="ms-3 text-danger font-weight-bold" *ngIf="companyConf.showStock && article.stock <= 0">
                  Sin stock
                </span>
              </div>

              <div class="article-name fa-1-25x w-100"
                   [ngClass]="{'font-weight-bold': companyConf.boldNameArticle,
                               'text-center': companyConf.centerNameArticle,
                                'text-left': !companyConf.centerNameArticle}"
                   #myDivs>
                <h2>
                  {{article.nameClient | titlecase}}
                </h2>
              </div>

              <div class="fa-1-25x text-left mb-2 mt-2" *ngIf="article.format">
                <a class="text-decoration-none text-dark" [ngClass]="{'font-weight-bold': companyConf.boldPrice}"
                   (click)="article.articleTag = false;">
                  {{(((article.format == 1) ? article.priceIva : (article.approxWeight > 0) ? round(article.priceIva * article.approxWeight) : article.priceIva)
                  | currency: 'EUR') +
                  (article.format == 1 && companyConf.companyException != 'ENJA' ? '/Kg' :
                  (article.format != 1 && companyConf.companyException != 'ENJA' ? '/Ud' : '') )}}
                </a>
              </div>

              <div class="d-flex justify-content-center w-100" (click)="stopPropagation($event)" *ngIf="companyConf.utilizarMultiplesFormatos"
                   [ngClass]="{'oculto': article.approxWeight == undefined || article.approxWeight == 0}">
                <div class="mt-2 mb-2 p-1 pointer toggle-button-group text-decoration-none" (click)="stopPropagation($event)">
                  <div class="w-50">
                    <div class="toggle-button p-1  w-100 text-center"
                         [ngClass]="{'active': article.format != 1}"
                         id="ud" onclick="toggle('ud')" (click)="stopPropagation($event);article.format = 2; article.quantity = 0; article.lumps = 0; setQuantity(article)">ud</div>
                  </div>
                  <div class="w-50">
                    <div class="toggle-button p-1 w-100 text-center"
                         [ngClass]="{'active': article.format == 1}" (click)="stopPropagation($event);article.format = 1; article.quantity = 0; article.lumps = 0; setQuantity(article)">kg</div>
                  </div>
                </div>
              </div>


              <div class="select-container mt-2" (click)="stopPropagation($event)">
                <div class="d-flex justify-content-center w-100 mb-2">
                  <button class="btn btn-add text-dark"
                          (click)="subtractQuantity((article.format == 1 ? 1 : 0), article); companyConf.companyException != 'ENJA' ? setQuantity(article) : false;">
                    <i class="fa-solid fa-minus"></i>
                  </button>

                  <div class="quantity-div text-center pt-2">
                    <span *ngIf="article.quantity == undefined || article.quantity == 0; else elseQuantity">0,00</span>
                    <ng-template #elseQuantity>
                      {{((article.lumps > 0 && article.approxWeight > 0) ? article.lumps : article.quantity) | number: '.2'}}
                    </ng-template>
                  </div>

                  <button class="btn btn-add text-dark"
                          (click)="addQuantity((article.format == 1 ? 1 : 0), article); companyConf.companyException != 'ENJA' ? setQuantity(article) : false;">
                    <i class="fa-solid fa-plus"></i>
                  </button>
                </div>
              </div>
              <button class="btn btn-primary text-light w-100" (click)="stopPropagation($event); setQuantity(article);"
                      *ngIf="companyConf.companyException == 'ENJA'">
                <div *ngIf="article.id != articuloPulsado">
                  Añadir al carrito
                </div>

                  <div class="d-flex w-100 justify-content-center">
                    <div *ngIf="article.id == articuloPulsado"
                         style="background-image: url('assets/check.gif'); width: 30px; height: 30px; background-size: cover;" ></div>
                  </div>
              </button>
            </a>
          </div>
        </div>

        <!-- PAGINACIÓN ABAJO-->
        <div id="pagination-container" class="d-flex justify-content-center mb-3" *ngIf="articles.length > 40">
          <pagination-controls class="pagination justify-content-end font-weight-bold" previousLabel="Anterior"
                               nextLabel="Siguiente" style="font-size: 12px" (pageChange)="pageChanged($event)"></pagination-controls>

        </div>

        <div class="w-100 text-end" *ngIf="companyConf.subvencionadoKitDigital">
          Mostrando un total de {{articles.length}} artículos
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="align-items-center d-flex justify-content-center container-load w-100">
    <div class="w-100">
      <div class="w-100 d-flex justify-content-center mb-3">
        <img src="assets/loading/loading.gif"  alt="Cargando icono" title="Icono cargando datos"
             height="200" width="200"/>
      </div>
      <div class="text-center">
        <p style="font-size: 14px">Espere un momento</p>
      </div>
    </div>
  </div>
</ng-template>
