import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {AuthStorageService} from '../services/auth/auth-storage.service';

@Injectable({
  providedIn: 'root'
})
export class NotUserGuard  {
  constructor(private authStorageService: AuthStorageService, private router: Router) {}

  canActivate() {
  const user = this.authStorageService.getUser();
  if (user != null) {
  this.router.navigate(['/']);
  return false;
  } else {
  return true;
  }
  }
}
