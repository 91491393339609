import { createReducer, on } from '@ngrx/store';
import {
    leerArticulos,
    leerEmpresaInfo,
    leerEmpresaInfoBasica,
    leerEmpresaInfoTotal,
    leerFamilias,
    leerPaises,
    leerSuperGrupo,
    leerUsuarioInfoTotal,
    obtenerArticulosInfo,
    modificarArticulosError,
    obtenerEmpresaInfo,
    obtenerEmpresaInfoBasica,
    obtenerEmpresaInfoError,
    obtenerEmpresaInfoErrorBasica,
    obtenerEmpresaInfoErrorTotal,
    obtenerEmpresaInfoTotal,
    obtenerFamilias,
    obtenerFamiliasError,
    obtenerPaises,
    obtenerPaisesError,
    obtenerSuperGrupo,
    obtenerSuperGrupoError,
    obtenerUsuarioInfoErrorTotal,
    obtenerUsuarioInfoTotal
} from 'src/app/redux/empresa.actions';

export const initialState: any | undefined = null;

export const empresaInfoReducer = createReducer(
  initialState,
    on(leerEmpresaInfo, (state) => ({ value: state })),
    on(obtenerEmpresaInfo, (state, { value }) => value),
    on(obtenerEmpresaInfoError, (state, { error }) => ({ value: state, loading: false, error })),
);

export const empresaInfoBasicaReducer = createReducer(
    initialState,
    on(leerEmpresaInfoBasica, (state) => ({ value: state })),
    on(obtenerEmpresaInfoBasica, (state, { value }) => value),
    on(obtenerEmpresaInfoErrorBasica, (state, { error }) => ({ value: state, loading: false, error })),
);

export const empresaInfoTotalReducer = createReducer(
    initialState,
    on(leerEmpresaInfoTotal, (state) => ({ value: state })),
    on(obtenerEmpresaInfoTotal, (state, { value }) => value),
    on(obtenerEmpresaInfoErrorTotal, (state, { error }) => ({ value: state, loading: false, error })),
);


export const usuarioInfoReducer = createReducer(
    initialState,
    on(leerUsuarioInfoTotal, (state) => ({ value: state })),
    on(obtenerUsuarioInfoTotal, (state, { value }) => value),
    on(obtenerUsuarioInfoErrorTotal, (state, { error }) => ({ value: state, loading: false, error })),
);



export const paisesReducer = createReducer(
    initialState,
    on(leerPaises, (state) => ({ value: state })),
    on(obtenerPaises, (state, { value }) => value),
    on(obtenerPaisesError, (state, { error }) => ({ value: state, loading: false, error })),
);

export const articulosReducer = createReducer(
    initialState,
    on(leerArticulos, (state) => ({ value: state })),
    on(obtenerArticulosInfo, (state, { value }) => value),
    on(modificarArticulosError, (state, { error }) => ({ value: state, loading: false, error })),
);

export const superGruposReducer = createReducer(
    initialState,
    on(leerSuperGrupo, (state) => ({ value: state })),
    on(obtenerSuperGrupo, (state, { value }) => value),
    on(obtenerSuperGrupoError, (state, { error }) => ({ value: state, loading: false, error })),
);


export const familiasReducer = createReducer(
    initialState,
    on(leerFamilias, (state) => ({ value: state })),
    on(obtenerFamilias, (state, { value }) => value),
    on(obtenerFamiliasError, (state, { error }) => ({ value: state, loading: false, error })),
);
