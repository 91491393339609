import {AfterViewInit, Component, ElementRef, Input, QueryList, Renderer2, ViewChild, ViewChildren} from '@angular/core';
import {Article} from '../../../interfaces/article';
import {CompanyConf} from '../../../interfaces/company-conf';

@Component({
  selector: 'app-carousel-article',
  templateUrl: './carousel-article.component.html',
  styleUrls: ['./carousel-article.component.scss']
})
export class CarouselArticleComponent implements AfterViewInit {

  constructor(private renderer: Renderer2) { }
  @Input() articles: Array<Article>;
  @Input() article: Article;
  @Input() companyConf: CompanyConf;
  @Input() paymentMethod: string;
  @ViewChild('CONTENT') elementView: ElementRef;
  @ViewChildren('myDivs') myDivs: QueryList<ElementRef>;

  getNewArticle() {
    let nombreArticulo = this.article.name.toLowerCase().split(' ').join('-').split('(').join('').split(')').join('').split('/').join('');
    let nombreGrupo = this.article.groupName.toLowerCase().split(' ').join('-').split('(').join('').split(')').join('').split('/').join('');
    return '/compra/' + nombreGrupo + '/' + nombreArticulo;
  }

  ngAfterViewInit(): void {
    this.myDivs.changes.subscribe(() => {
      this.setEqualHeight();
    });
  }

  setEqualHeight(): void {
    // Initialize a variable to store the maximum height
    let maxHeight = 0;

    // Loop through each ElementRef in myDivs
    this.myDivs.forEach(divRef => {
      const divHeight = divRef.nativeElement.clientHeight;

      // Update maxHeight if the current div is taller
      if (divHeight > maxHeight) {
        maxHeight = divHeight;
      }
    });

    // Apply the maximum height to all divs
    this.myDivs.forEach(divRef => {
      this.renderer.setStyle(divRef.nativeElement, 'height', maxHeight + 'px');
    });
  }
}
