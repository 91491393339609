<div *ngIf="article == undefined; else showArticle">
  <div class="align-items-center d-flex justify-content-center container-load">
    <div class="w-100">
      <div class="w-100 d-flex justify-content-center mb-3">
        <img src="assets/loading/loading.gif"  alt="Cargando icono" title="Icono cargando datos" style="height: 150px !important; width: auto !important;"/>
      </div>
      <div class="text-center">
        <h3>Estamos cargando el artículo seleccionado</h3>
        <p style="font-size: 14px">Espere un momento</p>
      </div>
    </div>
  </div>
</div>


<ng-template #showArticle>
  <div class="container-fluid mt-4">
    <div class="d-flex justify-content-center" style="min-height: 65vh">
      <div class=" col-12 col-md-8">
        <div id="container-location">
    <span class="navigation_page">
    <span><i class="fas fa-home"></i></span>
    <span class="navigation-pipe"> » </span>
    <span *ngIf="superGroup">
    <span *ngIf="superGroup.groups.length > 1">
    <a itemprop="url"
       [queryParams]="{ superGrupo: superGroup.id, grupo: '*' }"
       [routerLink]="redirectToGrupo(superGroup.name)">
      <span itemprop="title">{{superGroup.name}}</span>
    </a>
    </span>
    <span *ngIf="superGroup.groups.length > 1" class="navigation-pipe"> » </span>
    </span>

    <span *ngIf="!superGroup">
      <a itemprop="url"
         [queryParams]="{ superGrupo: 'none', grupo: groupId }"
         [routerLink]="redirectToGrupo(groupName)">
        <span itemprop="title">{{groupName}}</span>
      </a>
    </span>

    <span *ngIf="superGroup">
      <a [queryParams]="{ superGrupo: superGroup.id, grupo: groupId }"
         [routerLink]="redirectToGrupo(groupName)">
        <span itemprop="title">{{groupName}}</span>
      </a>
    </span>
    <span class="navigation-pipe"> » </span>
        <span *ngIf="article != undefined && !dropDownGauge">
          <span *ngIf="article.nameClient == '' || article.nameClient == undefined; else elseNameClient">{{article.name}}</span>
            <ng-template #elseNameClient>{{article.nameClient | titlecase}}</ng-template>
          </span>
        </span>
        </div>

        <app-article *ngIf="article" [article]="article" [navigationRoute]="navigationRouteCopy"
                     [indicateLumps]="indicateLumps"
                     [minPrice]="minPrice"
                     [companyConf]=" companyConf" [dropDownGauge] = "dropDownGauge"
                     [paymentMethod]="paymentMethod"
                     [priceRoute]="priceRoute" [isUser]="isUser"
                     [variasFotos]="variasFotos"
                     (numberArticles)="newArticleNumber($event)"
                     [homeDelivery]="homeDelivery"></app-article>

        <div *ngIf="articles">
          <app-carousel-article *ngIf="articles.length > 0" [articles]="articles" [article]="article"
                                [companyConf]="companyConf" [paymentMethod]="paymentMethod"></app-carousel-article>
        </div>

      </div>
    </div>
  </div>
</ng-template>


