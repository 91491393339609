import {SafeUrl} from '@angular/platform-browser';
import {CutType} from './cut-type';

export class BasicArticleQuantity {
  id: string;
  line: number;
  quantity: number;
}
export class Article {
  id: string;
  line: number;
  name: string;
  format: any;
  realFormatName: string;
  articleImgUrl: string;
  productImgUrl: string;
  img: SafeUrl;
  priceIva: number;
  price: number;
  priceIvaStr: number;
  nameClient: string;
  group: string;
  groupName: string;
  family: string;
  familyName: string;
  offer: number;
  superGroup: string;
  stock: number;
  gauge: string;
  gaugeName: string;
  articleTag: boolean;
  approxWeight: number;
  minimum: number;
  maximum: number;
  observations: string;
  ingredients: string;
  procedimientos: string;
  conservantes: string;
  metodoConservacion: string;
  imagenesAlergenos: Array<string> | undefined;

  search = false;
  quantity = 0;
  totalPrice = 0;
  totalPriceIva = 0;
  lumps: number;
  cut: string;
  cutName: string;
  remove: boolean;
  scientificName: string;
  obtaining: string;
  treatment: string;
  fao: string;
  fishingGear: string;
  capture: string;
  allergies: string;
  information: string;
  dropDownGauge: boolean; // Despegable calibres
  tiposCorte: Array<CutType>;
  articles: Array<Article>;

  constructor(id: string, name: string, format: string, realFormatName: string,
      articleImgUrl: string, productImgUrl: string, img: SafeUrl,
      priceIva: number, price: number,
      nameClient: string, group: string, groupName: string, family: string, familyName: string, offer: number, superGroup: string,
      stock: number, gauge: string, gaugeName: string, articleTag: boolean, approxWeight: number,
      minimum: number, maximum: number, ingredients: string) {
  this.id = id;
  this.name = name;
  this.format = format;
  this.realFormatName = realFormatName;
  this.priceIva = priceIva;
  this.price = price;
  this.nameClient = nameClient;
  this.group = group;
  this.groupName = groupName;
  this.family = family;
  this.familyName = familyName;
  this.offer = offer;
  this.superGroup = superGroup;
  this.stock = stock;
  this.gauge = gauge;
  this.gaugeName = gaugeName;
  this.articleTag = articleTag;
  this.approxWeight = approxWeight;
  this.minimum = minimum;
  this.maximum = maximum;
  this.ingredients = ingredients;
  this.img = img;
  this.productImgUrl = productImgUrl;
  this.articleImgUrl = articleImgUrl;

  this.lumps = 0;
  }
}

export interface ProductArticles {
  id: string;
  name: string;
  scientificName: string;
  img: SafeUrl;
  productImgUrl: string;
  group: string;
  groupName: string;
  family: string;
  familyName: string;
  superGroup: string;
  obtaining: string;
  treatment: string;
  conservation: string;
  fao: string;
  fishingGear: string;
  capture: string;
  allergies: string;
  information: string;
  dropDownGauge: boolean; // Despegable calibres
  tiposCorte: Array<CutType>;
  articles: Array<Article>;
}

export class ArticleCookie {
  id: string;
  line: number;
  name: string;
  format: string;
  img: SafeUrl;
  price: number;
  nameClient: string;
  group: string;
  family: string;
  quantity: number;
  totalPrice: number;
  observations: string;
  superGroup: string;
  stock: number;
  showPriceQuantityInfo: boolean;
  gaugeName: string;
  cutType: string;
  cutName: string;
  lumps: number;

  constructor(id: string, line: number, name: string, format: string, img: SafeUrl, price: number,
      nameClient: string, group: string, family: string, quantity: number, lumps: number, totalPrice: number,
      observations: string, cutType: string, cutName: string) {
  this.id = id;
  this.line = line;
  this.name = name;
  this.format = format;
  this.img = img;
  this.price = price;
  this.nameClient = nameClient;
  this.group = group;
  this.family = family;
  this.quantity = quantity;
  this.lumps = lumps;
  this.totalPrice = totalPrice;
  this.observations = observations;
  this.stock = 0;
  this.showPriceQuantityInfo = false;
  this.gaugeName = null;
  this.cutType = cutType;
  this.cutName = cutName;
  }
}

export class MostRequestedArticle {
  article: string;
  quantity: number;
}

export class DeleteArticle {
  article: string;
  line: string;
  cutType: string;

  constructor(article: string, line: string, cutType: string) {
  this.article = article;
  this.line = line;
  this.cutType = cutType;
  }
}

export class QuantityLumpsArticle {
  id: string;
  format: string;
  line: number;
  quantity: number;
  lumps: number;

  constructor(id: string, format: string, line: number, quantity: number, lumps: number) {
  this.id = id;
  this.format = format;
  this.line = line;
  this.quantity = quantity;
  this.lumps = lumps;
  }
}

export interface ArticlesImages {
  id: string;
  img: SafeUrl;
}

