import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {catchError, map } from 'rxjs/operators';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordGuard  {
  url;
  constructor(private router: Router, private authService: AuthService) {
  this.url = this.router.url;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean | Observable<UrlTree> | UrlTree {
  // @ts-ignore
  return this.authService.validatePasswordUrl(state.url.replace('/nuevaClave/', ''))
  .pipe(
    // Si la petición es exitosa se puede proceder
    map(() => true),
    catchError(() => {
    this.router.navigateByUrl('/iniciarSesion').then();
    return Observable.throw(false);
    })
  );
  }
}
