<div *ngIf="tpvParameters == undefined">
  <div *ngIf="newUser">{{loadUserInfo()}}</div>

  <!-- ______ NO REGISTER USER ____________ -->
  <div class="container" *ngIf="!userInfo && !showSuccessOrder">
    <div *ngIf="!continueAuth; else elseOrder">
      <div class="row mb-4 " *ngIf="!showNewRegister">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <div class="card p-2">
            <div class="card-body">
              <h1 class="card-title mb-4 mt-1 max mb-0 text-dark">
                <b>Inicie sesión con sus credenciales</b>
              </h1>
              <form (ngSubmit)="onSubmitLogin()" [formGroup]="userForm" novalidate>
                <div *ngIf="isLoginFailed && !lockUser" class="error">Usuario o contraseña incorrectos</div>
                <div *ngIf="isLoginFailed && lockUser" class="error">En estos momentos usted no tiene acceso
                  a nuestra plataforma.</div>
                <div class="row">
                  <div class="col-sm-12">
                    <mat-form-field class="w-100">
                      <mat-label>Usuario</mat-label>
                      <input type="text" class="w-100" autocomplete="user" formControlName="user" matInput>
                      <div *ngIf="userForm.controls.user.touched && userForm.controls.user.errors?.required"
                        class="error">Campo obligatorio
                      </div>
                      <div *ngIf="userForm.controls.user.touched && !userForm.controls.user.errors?.required
        && (userForm.controls.user.errors?.userIncorrect)" class="error">Formato incorrecto.
                      </div>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-12">
                    <mat-form-field class="w-100">
                      <mat-label>Contraseña</mat-label>
                      <input [type]="hide ? 'password' : 'text'" class="w-100" autocomplete="password"
                        formControlName="password" matInput>
                      <mat-icon matSuffix (click)="hide = !hide">
                        <span *ngIf="!hide; else elseHide"><i class="fas fa-eye-slash fa-xs"></i></span>
                        <ng-template #elseHide><i class="fas fa-eye fa-xs"></i></ng-template>
                      </mat-icon>
                      <div *ngIf="userForm.controls.password.touched && userForm.controls.password.errors?.required"
                        class="error">Campo obligatorio
                      </div>
                      <div *ngIf="userForm.controls.password.touched &&
              !userForm.controls.password.errors?.required
              && userForm.controls.password.errors?.validLettersIncorrect" class="error">La contraseña no puede
                        contener el carácter '
                      </div>
                    </mat-form-field>
                  </div>
                </div>
                <button type="submit" class="btn btn-primary text-light w-100" [disabled]="!userForm.valid">Iniciar
                  Sesión</button>
              </form>
            </div>
          </div>

          <div style="margin: 1.25em 10px 10px 10px;">
            <h5><span>Crear una nueva cuenta</span></h5>
            <a class="btn btn-outline-info w-100 mb-4" [routerLink]="'/registrarse'">
              REGISTRARME
            </a>
          </div>
          <div style="margin: 1.25em 10px 10px 10px;">
            <h5><span>Tramitar el pedido sin registrarse</span></h5>
            <a class="btn btn-outline-info w-100 mb-4" (click)="continueAuth = true">
              CONTINUAR SIN REGISTRARME
            </a>
          </div>
        </div>
        <div class="col-md-3"></div>
      </div>
    </div>

    <ng-template #elseOrder>
      <div class="row flex-column-reverse flex-md-row">
        <div class="col-md-7">
          <!-- DATOS PERSONALES -->
          <div class="card">
            <div class="card-header bg-white text-black-50 d-flex" *ngIf="contactValid">
              <div style="width: 90%">
                <h2 class="order-title">1. Datos de contacto</h2>
              </div>
              <div style="width: 10%" *ngIf="!userInfo">
                <button type="button" class="btn btn-outline-secondary" (click)="contactValid = false"><i
                    class="far fa-edit"></i></button>
              </div>
            </div>
            <div class="card-header bg-white" *ngIf="!contactValid">
              <h2 class="order-title">1. Datos de contacto</h2>
            </div>

            <div class="card-body" *ngIf="!contactValid">
              <form (ngSubmit)="contactValid = true" [formGroup]="contactForm" novalidate>
                <div class="row">
                  <div class="col-sm-6">
                    <mat-form-field style="width: 90%; !important">
                      <mat-label>Nombre</mat-label>
                      <input type="text" class="w-100" formControlName="name" autocomplete="name" matInput>
                      <div *ngIf="contactForm.controls.name.touched && contactForm.controls.name.errors?.required"
                        class="error w-100">Campo obligatorio
                      </div>
                    </mat-form-field>
                  </div>

                  <div class="col-sm-6">
                    <mat-form-field style="width: 90%; !important">
                      <mat-label>Apellidos</mat-label>
                      <input type="text" class="w-100" formControlName="surnames" autocomplete="family-name" matInput>
                      <div
                        *ngIf="contactForm.controls.surnames.touched && contactForm.controls.surnames.errors?.required"
                        class="error">Campo obligatorio
                      </div>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-6">
                    <mat-form-field style="width: 90%; !important">
                      <mat-label>Teléfono</mat-label>
                      <input type="text" class="w-100" formControlName="phoneNumber" autocomplete="tel" matInput>
                      <div
                        *ngIf="contactForm.controls.phoneNumber.touched && contactForm.controls.phoneNumber.errors?.required"
                        class="error">Campo obligatorio
                      </div>
                      <div
                        *ngIf="contactForm.controls.phoneNumber.touched && contactForm.controls.phoneNumber.errors?.pattern"
                        class="error">Formato incorrecto.
                      </div>
                    </mat-form-field>
                  </div>

                  <div class="col-sm-6">
                    <mat-form-field style="width: 90%; !important;" class="mb-0 pb-0">
                      <mat-label>Correo electrónico</mat-label>
                      <input type="text" class="w-100 mb-0" formControlName="email" autocomplete="email" matInput>
                      <div *ngIf="contactForm.controls.email.touched && contactForm.controls.email.errors?.required"
                        class="error">Campo obligatorio
                      </div>
                      <div *ngIf="contactForm.controls.email.touched && contactForm.controls.email.errors?.email"
                        class="error">Formato incorrecto.
                      </div>
                    </mat-form-field>
                  </div>
                </div>

                <div class="max d-flex justify-content-center">
                  <button type="submit" class="btn btn-primary text-light w-100" [disabled]="!contactForm.valid">Continuar</button>
                </div>
              </form>
            </div>

            <div class="card-body" *ngIf="contactValid">
              <span><b>Nombre:</b> {{contactForm.controls['name'].value + ' ' +
                contactForm.controls['surnames'].value}}</span><br />
              <span><b>Teléfono Principal:</b> {{contactForm.controls['phoneNumber'].value}}</span><br />
              <span><b>Correo electrónico:</b> {{contactForm.controls['email'].value}}</span>
            </div>
          </div>

          <!-- DIRECCIÓN -->
          <div class="card">
            <div class="card-header bg-white text-black-50 d-flex " *ngIf="!contactValid || directionValid">
              <div style="width: 90%">
                <h2 class="order-title">2. Datos de entrega</h2>
              </div>
              <div style="width: 10%" *ngIf="contactValid">
                <button type="button" class="btn btn-outline-secondary" (click)="directionValid = false;
                               selectedStorePickupDate = null; selectPickUpDate = false;
                               selectPickUpDate = false;
                               selectDirection = true;"><i class="far fa-edit"></i>
                </button>
              </div>
            </div>
            <div class="card-header bg-white" *ngIf="contactValid && !directionValid">
              <h2 class="order-title">2. Datos de entrega</h2>
            </div>

            <div class="card-body w-100" *ngIf="contactValid && !directionValid">
              <div *ngIf="companyConf.puntosRecogida; else direcciones" class="card-body">
                <div class="w-100" *ngIf="selectPickUpDate">
                  <div *ngIf="companyConf.rutasRecogida != undefined">
                    <div class="row" *ngIf="companyConf.rutasRecogida.length > 0">
                      <div class="col-12">
                        <mat-form-field class="w-100">
                          <mat-label>Punto de recogida</mat-label>
                          <mat-select class="w-100" [(ngModel)]="ruta"
                            (ngModelChange)="cambiarHorarioRecogida()">
                            <mat-option *ngFor="let ruta of companyConf.rutasRecogida" [value]="ruta.id">{{ruta.nombre}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>


                  <div class="row" *ngIf="companyConf.storePickUpSchedule">
                    <div class="col-sm-12">
                      <mat-form-field class="w-100">
                        <mat-label>Hora de recogida</mat-label>
                        <mat-select class="w-100" [(ngModel)]="scheduleHour">
                          <mat-option *ngFor="let hour of companyConf.storePickUpSchedule"
                            [value]="hour">{{hour}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div *ngIf="ruta != undefined">
                    <form [formGroup]="deliveryDate" novalidate>
                      <div class="row">
                        <div class="col-sm-12">
                          <mat-form-field class="w-100" appearance="fill">
                            <mat-label>Fecha de recogida</mat-label>
                            <input class="w-100" [matDatepicker]="myPicker"
                              [matDatepickerFilter]="calendarioRecogidaRuta" (click)="myPicker.open()"
                              (dateChange)="selectPickupCalendarDate($event)" formControlName="deliveryDate" matInput
                              readonly required>
                            <mat-datepicker-toggle matSuffix [for]="myPicker"></mat-datepicker-toggle>
                            <mat-datepicker touchUi #myPicker></mat-datepicker>
                          </mat-form-field>
                        </div>
                      </div>
                    </form>
                  </div>

                </div>
              </div>

              <ng-template #direcciones>
                <div *ngIf="companyConf.storePickup" class="row mb-3">
                  <div class="col-12 mb-2" *ngIf="companyConf.storePickUpDates.length > 0">
                    <button type="button" class="btn btn-outline-primary w-100"
                      (click)="storePickup = true; selectPickUpDateCalendar = true; selectPickUpDate = true; this.deliveryDate.controls['deliveryDate'].setValue(null); selectDirection = false; seleccionarRuta(); setHomeDeliveryFn(false);">
                      <i class="far fa-calendar-alt me-1"></i>{{companyConf.storePickUpDatesText}}
                    </button>
                  </div>

                  <div class="col-12 mb-2">
                    <button type="button" class="btn btn-outline-primary w-100"
                      (click)="storePickup = true; selectPickUpDate = true; ruta = null; selectPickUpDateCalendar = false;this.deliveryDate.controls['deliveryDate'].setValue(null); selectDirection = false; setHomeDeliveryFn(false);">
                      <i class="fas fa-store-alt me-1"></i>Recoger en tienda
                    </button>
                  </div>

                  <div class="col-12 mb-2" *ngIf="!companyConf.soloRecogidaTienda">
                    <button type="button" class="btn btn-outline-primary w-100"
                      (click)="storePickup = false; selectDirection = true; ruta = null; selectPickUpDate = false;this.deliveryDate.controls['deliveryDate'].setValue(null);  selectPickUpDateCalendar = false; setHomeDeliveryFn(true);">
                      <i class="fas fa-truck me-1"></i>Envío a domicilio
                    </button>
                  </div>
                </div>

                <div class="w-100" *ngIf="selectPickUpDate">
                  <div *ngIf="!selectPickUpDateCalendar; else calendar">
                    <form [formGroup]="deliveryDate" novalidate>
                      <div class="row">
                        <div class="col-sm-12">
                          <mat-form-field class="w-100" appearance="fill">
                            <mat-label>Fecha de recogida</mat-label>
                            <input class="w-100" [matDatepicker]="myPicker"
                              [matDatepickerFilter]="calendarioRecogidaEnTienda" (click)="myPicker.open()"
                              (dateChange)="selectPickupCalendarDate($event)" formControlName="deliveryDate" matInput
                              readonly required>
                            <mat-datepicker-toggle matSuffix [for]="myPicker"></mat-datepicker-toggle>
                            <mat-datepicker touchUi #myPicker></mat-datepicker>
                          </mat-form-field>
                        </div>
                      </div>
                    </form>
                  </div>

                  <ng-template #calendar>
                    <form [formGroup]="deliveryDate" novalidate>
                      <div class="row">
                        <div class="col-sm-12">
                          <mat-form-field class="w-100" appearance="fill">
                            <mat-label>Fecha de recogida</mat-label>
                            <input class="w-100" [matDatepicker]="myPicker"
                              [matDatepickerFilter]="calendarioRecogidaEnTienda" (click)="myPicker.open()"
                              (dateChange)="selectPickupCalendarDate($event)" formControlName="deliveryDate" matInput
                              readonly required>
                            <mat-datepicker-toggle matSuffix [for]="myPicker"></mat-datepicker-toggle>
                            <mat-datepicker touchUi #myPicker></mat-datepicker>
                          </mat-form-field>
                        </div>
                      </div>
                    </form>
                  </ng-template>

                  <div class="row" *ngIf="companyConf.storePickUpSchedule">
                    <div class="col-sm-12">
                      <mat-form-field class="w-100">
                        <mat-label>Hora de recogida</mat-label>
                        <mat-select class="w-100" [(ngModel)]="scheduleHour">
                          <mat-option *ngFor="let hour of companyConf.storePickUpSchedule"
                            [value]="hour">{{hour}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div *ngIf="companyConf.rutasRecogida != undefined && selectPickUpDateCalendar">
                    <div class="row" *ngIf="companyConf.rutasRecogida.length > 0">
                      <div class="col-12">
                        <mat-form-field class="w-100">
                          <mat-label>Lugar de recogida</mat-label>
                          <mat-select class="w-100" [(ngModel)]="ruta">
                            <mat-option *ngFor="let ruta of companyConf.rutasRecogida"
                              [value]="ruta.id">{{ruta.nombre}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>

                <form (ngSubmit)="addDirection()" [formGroup]="directionForm" novalidate *ngIf="selectDirection">
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <ng-select formControlName="country" placeholder="País*" (change)="getProvinces()">
                          <ng-option *ngFor="let country of countries" [value]="country.id">{{country.name}}</ng-option>
                        </ng-select>
                        <div
                          *ngIf="directionForm.controls.country.touched && directionForm.controls.country.errors?.required"
                          class="error">Campo obligatorio
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-6">
                      <div class="form-group">
                        <ng-select bindLabel="name" formControlName="province" placeholder="Provincia*"
                          (change)="getCities()">
                          <ng-option *ngFor="let province of provinces"
                            [value]="province.id">{{province.name}}</ng-option>
                        </ng-select>
                        <div
                          *ngIf="directionForm.controls.province.touched && directionForm.controls.province.errors?.required"
                          class="error">Campo obligatorio
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row select-container">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <ng-select bindLabel="name" formControlName="city" placeholder="Población*" (change)="setMsg()">
                          <ng-option value="default">Seleccione una ciudad</ng-option>
                          <ng-option *ngFor="let city of cities" [value]="city.id">{{city.name}},
                            {{directionForm.controls.province.value}}{{city.id}}
                          </ng-option>
                        </ng-select>
                        <div
                          *ngIf="directionForm.controls.city.touched &&
                            (directionForm.controls.city.errors?.required || directionForm.controls.city.errors?.defaultOption)"
                          class="error">Campo obligatorio
                        </div>
                        <div *ngIf="msgInfoDays" class="text-info">
                          <div [innerHTML]="msgInfoDays"></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-12">
                      <mat-form-field class="w-100">
                        <mat-label>Dirección</mat-label>
                        <input type="text" class="w-100" placeholder="Ej: Calle Alcalá, 48, 1ºA"
                          formControlName="direction"  autocomplete="street-address" matInput>
                        <div
                          *ngIf="directionForm.controls.direction.touched && directionForm.controls.direction.errors?.required"
                          class="error">Campo obligatorio
                        </div>
                      </mat-form-field>
                    </div>
                  </div>

                  <form [formGroup]="deliveryDate" novalidate>
                    <div class="row">
                      <div class="col-12">
                        <mat-form-field class="w-100" appearance="fill">
                          <mat-label>Fecha de entrega</mat-label>
                          <input class="w-100" [matDatepicker]="myPicker" [matDatepickerFilter]="calendarioEntrega"
                            (click)="myPicker.open()" (dateChange)="seleccionarFechaEntrega($event)"
                            formControlName="deliveryDate" matInput readonly required>
                          <mat-datepicker-toggle matSuffix [for]="myPicker"></mat-datepicker-toggle>
                          <mat-datepicker touchUi #myPicker></mat-datepicker>
                        </mat-form-field>
                      </div>
                    </div>
                  </form>

                  <div class="max d-flex justify-content-center">
                    <button type="submit" class="btn btn-primary text-light w-100"
                            [disabled]="!(directionForm.valid && selectedStorePickupDate != undefined)">Continuar</button>
                  </div>
                </form>
              </ng-template>
            </div>

            <div class="card-body" *ngIf="directionValid && companyConf.puntosRecogida">
              <div><b>Recogida el</b> {{selectedStorePickupDate.value + ' sobre las ' + scheduleHour}}</div>
              <div *ngIf="this.ruta != null"><b>Punto de recogida:</b> {{nombreRuta()}}</div>
            </div>
            <div class="card-body" *ngIf="storePickup && directionValid  && !companyConf.puntosRecogida">
              <div><b>Recogida en tienda:</b> {{selectedStorePickupDate.value + ' ' + scheduleHour}}</div>
              <div *ngIf="this.ruta != null"><b>Lugar de recogida:</b> {{nombreRuta()}}</div>
            </div>
            <div class="card-body" *ngIf="!storePickup && directionValid  && !companyConf.puntosRecogida && selectedDirection != undefined">
              <span>
                <b>Código postal:</b> {{selectedDirection.province + selectedDirection.city}}
              </span><br />
              <span><b>Dirección:</b> {{selectedDirection.direction}}</span><br />
              <div><b>Fecha de envío:</b> {{selectedStorePickupDate.value}}</div>
            </div>
          </div>

          <!-- DATOS DEL PEDIDO -->
          <div class="card">
            <div class="card-header bg-white text-black-50" *ngIf="!contactValid || !directionValid; else datosPedido">
              <h2 class="order-title">3. Datos del pedido</h2>
            </div>
            <ng-template #datosPedido>
              <div class="card-header bg-white">
                <h2 class="order-title">3. Datos del pedido</h2>
              </div>

              <div class="card-body">
                <form [formGroup]="observations">
                  <div class="row">
                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <mat-label>Observaciones</mat-label>
                        <textarea type="text" class="w-100" placeholder="Ej: No estoy en casa por la mañana"
                          cdkTextareaAutosize #autosize="cdkTextareaAutosize" formControlName="observations"
                          matInput></textarea>
                      </mat-form-field>
                    </div>
                  </div>
                </form>
              </div>
            </ng-template>

          </div>

          <!-- PROCESO DE PAGO -->
          <div class="card">
            <div class="card-header bg-white text-black-50" *ngIf="!contactValid || !directionValid">
              <h2 class="order-title">4. Proceso de Pago</h2>
            </div>

            <div class="card-header bg-white" *ngIf="contactValid  && directionValid">
              <h2 class="order-title">4. Proceso de Pago</h2>
            </div>

            <div class="card-body" *ngIf="contactValid && directionValid">

              <div *ngIf="!companyConf.pasarelaActiva; else pasarela" class="w-100">
                <div class="d-flex justify-content-center mb-2 w-100">
                  <button class="btn btn-primary text-light btn-delivery-data w-100" [disabled]="!deliveryDateValid"
                    (click)="makeOrder(AddingOrder)"><i class="fas fa-store-alt"></i> Pagar en tienda</button>
                </div>
              </div>

              <ng-template #pasarela>
                <div>
                  <mat-checkbox class="m-1" [(ngModel)]="aceptoPoliticas">
                    Acepto la <a routerLink="/política-devolucion-y-envio">política de devoluciones y envío</a>
                  </mat-checkbox>
                </div>
                <div class="w-100 d-flex justify-content-center mb-2" *ngIf="!userInfo && selectDirection">
                  <button class="btn btn-primary text-light btn-delivery-data" [disabled]="!aceptoPoliticas"
                    (click)="paymentOrder(AddingOrder)"><i class="far fa-credit-card me-1"></i>Realizar Pago</button>
                </div>
                <div class="w-100" *ngIf="!userInfo && !selectDirection">
                  <div class="row">
                    <div class="col-12 d-flex justify-content-center  mb-2">
                      <button class="btn btn-primary text-light btn-delivery-data" [disabled]="!aceptoPoliticas"
                        (click)="paymentOrder(AddingOrder)"><i class="far fa-credit-card me-1"></i>Realizar
                        Pago</button>
                    </div>
                  </div>
                </div>
                <div *ngIf="userInfo">
                  <div class="w-100 d-flex justify-content-center mb-2" *ngIf="selectDirection">
                    <button class="btn btn-primary text-light btn-delivery-data" [disabled]="!aceptoPoliticas"
                      (click)="paymentOrder(AddingOrder)"><i class="far fa-credit-card me-1"></i>Realizar Pago</button>
                  </div>
                  <div class="w-100 d-flex justify-content-center" *ngIf="!selectDirection">
                    <div class="row">
                      <div class="col-md-6 d-flex justify-content-center mb-2">
                        <button class="btn btn-primary text-light btn-delivery-data" [disabled]="!aceptoPoliticas"
                          (click)="paymentOrder(AddingOrder)">Realizar Pago</button>
                      </div>
                      <div class="col-md-6 d-flex justify-content-center mb-2">
                        <button class="btn btn-primary text-light btn-delivery-data" [disabled]="!aceptoPoliticas"
                          (click)="makeOrder(AddingOrder)"><i class="fas fa-store-alt"></i> Pagar en tienda</button>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>

            </div>
          </div>
        </div>

        <ng-container *ngTemplateOutlet="resumenPedido"> </ng-container>
      </div>
    </ng-template>
  </div>

  <!-- ______ USER REGISTER ____________ -->
  <div class="container" *ngIf="userInfo && !showSuccessOrder">
    <div class="row flex-column-reverse flex-md-row">
      <div class="col-md-7">
        <!-- DATOS PERSONALES -->
        <div class="card">
          <div class="card-header bg-white text-black-50">
            <h2 class="order-title">1. Datos de contacto</h2>
          </div>

          <div class="card-body" *ngIf="!contactValid">
            <span><b>Nombre:</b> {{userInfo.name}}</span><br />
            <span *ngIf="userInfo.phone"><b>Teléfono Principal:</b> {{userInfo.phone}}</span><br />
            <span *ngIf="userInfo.phone2"><b>Teléfono Secundario:</b> {{userInfo.phone2}}<br /></span>
            <span *ngIf="userInfo.email"><b>Correo electrónico:</b> {{userInfo.email}}</span>
          </div>
        </div>

        <!-- DIRECCIÓN -->
        <div class="card">
          <div class="card-header bg-white text-black-50 d-flex" *ngIf="contactUserInvalid || selectedStorePickupDate">
            <div style="width: 90%">
              <h2 class="order-title">2. Datos de entrega</h2>
            </div>
            <div style="width: 10%">
              <button type="button" class="btn btn-outline-secondary" (click)="editClientDirection()"><i
                  class="far fa-edit"></i>
              </button>
            </div>
          </div>
          <div class="card-header bg-white" *ngIf="!contactUserInvalid && !selectedStorePickupDate">
            <h2 class="order-title">2. Datos de entrega</h2>
          </div>

          <div *ngIf="companyConf.puntosRecogida; else direcciones" class="card-body">
            <div class="card-body" *ngIf="directionValid; else elseSeleccionarPuntoRecogida">
              <div><b>Recogida el</b> {{selectedStorePickupDate.value + ' sobre las ' + scheduleHour}}</div>
              <div *ngIf="this.ruta != null"><b>Punto de recogida:</b> {{nombreRuta()}}</div>
            </div>

            <ng-template #elseSeleccionarPuntoRecogida>
              <div class="w-100" *ngIf="selectPickUpDate">
                <div *ngIf="companyConf.rutasRecogida != undefined">
                  <div class="row" *ngIf="companyConf.rutasRecogida.length > 0">
                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <mat-label>Punto de recogida</mat-label>
                        <mat-select class="w-100" [(ngModel)]="ruta"
                          (ngModelChange)="cambiarHorarioRecogida()">
                          <mat-option *ngFor="let ruta of companyConf.rutasRecogida"
                            [value]="ruta.id">{{ruta.nombre}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <div class="row" *ngIf="companyConf.storePickUpSchedule">
                  <div class="col-sm-12">
                    <mat-form-field class="w-100">
                      <mat-label>Hora de recogida</mat-label>
                      <mat-select class="w-100" [(ngModel)]="scheduleHour">
                        <mat-option *ngFor="let hour of companyConf.storePickUpSchedule"
                          [value]="hour">{{hour}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <div *ngIf="ruta != undefined">
                  <form [formGroup]="deliveryDate" novalidate>
                    <div class="row">
                      <div class="col-sm-12">
                        <mat-form-field class="w-100" appearance="fill">
                          <mat-label>Fecha de recogida</mat-label>
                          <input class="w-100" [matDatepicker]="myPicker" [matDatepickerFilter]="calendarioRecogidaRuta"
                            (click)="myPicker.open()" (dateChange)="selectPickupCalendarDate($event)"
                            formControlName="deliveryDate" matInput readonly required>
                          <mat-datepicker-toggle matSuffix [for]="myPicker"></mat-datepicker-toggle>
                          <mat-datepicker touchUi #myPicker></mat-datepicker>
                        </mat-form-field>
                      </div>
                    </div>
                  </form>
                </div>

              </div>
            </ng-template>
          </div>


          <ng-template #direcciones>
            <div class="card-body" *ngIf="storePickup && directionValid">
              <div><b>Recogida en tienda:</b> {{selectedStorePickupDate.value + ' ' + scheduleHour}}</div>
              <div *ngIf="this.ruta != null"><b>Lugar de recogida:</b> {{nombreRuta()}}</div>
            </div>

            <!-- DIRECCIÓN EMPRESAS O CLIENTES CON RUTAS-->
            <div *ngIf="!newDirection" class="card-body w-100">
              <div class="w-100" *ngIf="showDirections || editDirection || selectedStorePickupDate == null">
                <div *ngIf="companyConf.storePickup" class="row mb-3">
                  <div class="col-12 mb-2" *ngIf="companyConf.storePickUpDates.length > 0">
                    <button type="button" class="btn btn-outline-primary w-100"
                      (click)="storePickup = true; selectPickUpDateCalendar = true; selectedStorePickupDate = null; selectPickUpDate = true; this.deliveryDate.controls['deliveryDate'].setValue(null); selectDirection = false; seleccionarRuta(); setHomeDeliveryFn(false);">
                      <i class="far fa-calendar-alt me-1"></i>{{companyConf.storePickUpDatesText}}
                    </button>
                  </div>

                  <div class="col-12 mb-2">
                    <button type="button" class="btn btn-outline-primary w-100"
                      (click)="storePickup = true; selectPickUpDate = true; ruta = null;  selectedStorePickupDate = null; selectPickUpDateCalendar = false;this.deliveryDate.controls['deliveryDate'].setValue(null); selectDirection = false; setHomeDeliveryFn(false);">
                      <i class="fas fa-store-alt me-1"></i>Recoger en tienda
                    </button>
                  </div>

                  <div class="col-12 mb-2" *ngIf="!companyConf.soloRecogidaTienda">
                    <button type="button" class="btn btn-outline-primary w-100"
                      (click)="inicializarEscogerDireccion()">
                      <i class="fas fa-truck me-1"></i>Envío a domicilio
                    </button>
                  </div>
                </div>
              </div>

              <div class="w-100" *ngIf="selectPickUpDate">
                <div *ngIf="!selectPickUpDateCalendar; else calendar">
                  <form [formGroup]="deliveryDate" novalidate>
                    <div class="row">
                      <div class="col-sm-12">
                        <mat-form-field class="w-100" appearance="fill">
                          <mat-label>Fecha de recogida</mat-label>
                          <input class="w-100" [matDatepicker]="myPicker"
                            [matDatepickerFilter]="calendarioRecogidaEnTienda" (click)="myPicker.open()"
                            (dateChange)="selectPickupCalendarDate($event)" formControlName="deliveryDate" matInput
                            readonly required>
                          <mat-datepicker-toggle matSuffix [for]="myPicker"></mat-datepicker-toggle>
                          <mat-datepicker touchUi #myPicker></mat-datepicker>
                        </mat-form-field>
                      </div>
                    </div>
                  </form>
                </div>

                <ng-template #calendar>
                  <form [formGroup]="deliveryDate" novalidate>
                    <div class="row">
                      <div class="col-sm-12">
                        <mat-form-field class="w-100" appearance="fill">
                          <mat-label>Fecha de recogida</mat-label>
                          <input class="w-100" [matDatepicker]="myPicker"
                            [matDatepickerFilter]="calendarioRecogidaEnTienda" (click)="myPicker.open()"
                            (dateChange)="selectPickupCalendarDate($event)" formControlName="deliveryDate" matInput
                            readonly required>
                          <mat-datepicker-toggle matSuffix [for]="myPicker"></mat-datepicker-toggle>
                          <mat-datepicker touchUi #myPicker></mat-datepicker>
                        </mat-form-field>
                      </div>
                    </div>
                  </form>
                </ng-template>

                <div class="row" *ngIf="companyConf.storePickUpSchedule">
                  <div class="col-sm-12">
                    <mat-form-field class="w-100">
                      <mat-label>Hora de recogida</mat-label>
                      <mat-select class="w-100" [(ngModel)]="scheduleHour">
                        <mat-option *ngFor="let hour of companyConf.storePickUpSchedule"
                          [value]="hour">{{hour}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <div *ngIf="companyConf.rutasRecogida != undefined && selectPickUpDateCalendar">
                  <div class="row" *ngIf="companyConf.rutasRecogida.length > 0">
                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <mat-label>Lugar de recogida</mat-label>
                        <mat-select class="w-100" [(ngModel)]="ruta">
                          <mat-option *ngFor="let ruta of companyConf.rutasRecogida"
                            [value]="ruta.id">{{ruta.nombre}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>

              <!-- DIRECCIÓN SELECCIONADA -->
              <div class="container" *ngIf="!showDirections && !selectPickUpDate && !storePickup">
                <div class="card border-info bg-direction" style="margin-top: 1.25em">
                  <div *ngIf="!selectedDirection">
                    <div class="container" *ngIf="userInfo.isInRoute == 1 ">
                      <span>{{userInfo.direction}}</span><br />
                      <span>{{userInfo.countryName}}, {{userInfo.provinceName}}, {{userInfo.cityName}},
                        {{userInfo.province}}{{userInfo.city}}<br /></span>
                      <span *ngIf="userInfo.phone">{{userInfo.phone}}<br /></span>
                    </div>

                    <div class="container text-white no-direction" *ngIf="userInfo.isInRoute == 0">
                      <b>Dirección no disponible - </b>
                      <span>{{userInfo.direction}}</span><br />
                      <span>{{userInfo.countryName}}, {{userInfo.provinceName}}, {{userInfo.cityName}},
                        {{userInfo.province}}{{userInfo.city}}</span>
                    </div>
                  </div>

                  <div class="container" *ngIf="selectedDirection">
                    <span>{{selectedDirection.direction}}</span><br />
                    <span>{{selectedDirection.countryName}}, {{selectedDirection.provinceName}},
                      {{selectedDirection.cityName}},
                      {{selectedDirection.province}}{{selectedDirection.city}}</span><br />
                    <span *ngIf="selectedDirection.phone">{{selectedDirection.phone}}<br /></span>
                  </div>
                </div>

                <form [formGroup]="deliveryDate" novalidate>
                  <div class="row">
                    <div class="col-sm-12">
                      <mat-form-field class="w-100" appearance="fill" *ngIf="selectDirection">
                        <mat-label>Fecha de entrega</mat-label>
                        <input class="w-100" [matDatepicker]="myPicker" [matDatepickerFilter]="calendarioEntregaUsuario"
                          (click)="myPicker.open()" (dateChange)="seleccionarFechaEntrega($event)"
                          formControlName="deliveryDate" matInput readonly required>
                        <mat-datepicker-toggle matSuffix [for]="myPicker"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #myPicker></mat-datepicker>
                      </mat-form-field>
                    </div>
                  </div>
                </form>
              </div>

              <div class="d-flex justify-content-center w-100" *ngIf="!showDirections && selectDirection">
                <button class="btn btn-outline-info" style="width: 200px; margin-bottom: 1.25em"
                  (click)="selectDirectionFn()">Cambiar dirección</button>
              </div>

              <!-- DIRECCIÓN NO SELECCIONADA -->
              <div class="container" *ngIf="showDirections && selectDirection  && !storePickup">
                <div class="card border-info bg-no-direction" style="margin-top: 1.25em">
                  <div class="container" (click)="selectPostalDirection()" *ngIf="userInfo.isInRoute == 1">
                    <span>{{userInfo.direction}}</span><br />
                    <span>{{userInfo.countryName}}, {{userInfo.provinceName}}, {{userInfo.cityName}},
                      {{userInfo.province}}{{userInfo.city}}</span>
                  </div>

                  <div class="container text-white no-direction" *ngIf="userInfo.isInRoute == 0">
                    <span>{{userInfo.direction}}</span><br />
                    <span>{{userInfo.countryName}}, {{userInfo.provinceName}}, {{userInfo.cityName}},
                      {{userInfo.province}}{{userInfo.city}}</span>
                  </div>
                </div>

                <div *ngFor="let direction of directions" class="card border-info bg-no-direction"
                  style="margin-top: 1.25em">
                  <div class="container" *ngIf="direction.isInRoute == 1" (click)="selectNewDirection(direction)">
                    <span>{{direction.direction}}</span><br />
                    <span>{{direction.countryName}}, {{direction.provinceName}},
                      {{direction.cityName}}, {{direction.province}}{{direction.city}}</span><br />
                    <span>{{direction.phone}}</span>
                  </div>

                  <div class="container text-white no-direction" *ngIf="direction.isInRoute == 0">
                    <b>DIRECCIÓN NO DISPONIBLE - </b><span>{{direction.direction}}</span><br />
                    <span>{{direction.countryName}}, {{direction.provinceName}},
                      {{direction.cityName}}, {{direction.province}}{{direction.city}}</span><br />
                    <span>{{direction.phone}}</span>
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-center w-100" *ngIf="showDirections && selectDirection">
                <button class="btn btn-dark" style="width: 13.75em; margin-right: 15px; margin-bottom: 1.25em"
                  (click)="showDirections = false">Cancelar</button>

                <button class="btn btn-outline-info" style="width: 13.75em; margin-bottom: 1.25em"
                  (click)="newDirection = true">Añadir dirección</button>
              </div>
            </div>

            <!-- DIRECCIÓN EMPRESAS O CLIENTES CON RUTAS -- ELEGIR NUEVA DIRECCIÓN-->
            <div class="card-body" *ngIf="newDirection">
              <form (ngSubmit)="addDirection()" [formGroup]="directionForm" novalidate>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <ng-select bindLabel="País" formControlName="country" placeholder="País*"
                        (change)="getProvinces()">
                        <ng-option *ngFor="let country of countries" [value]="country.id">{{country.name}}</ng-option>
                      </ng-select>
                      <div
                        *ngIf="directionForm.controls.country.touched && directionForm.controls.country.errors?.required"
                        class="error">Campo obligatorio
                      </div>
                    </div>

                  </div>

                  <div class="col-sm-6">
                    <div class="form-group">
                      <ng-select bindLabel="name" formControlName="province" placeholder="Provincia*"
                        (change)="getCities()">
                        <ng-option *ngFor="let province of provinces"
                          [value]="province.id">{{province.name}}</ng-option>
                      </ng-select>
                      <div
                        *ngIf="directionForm.controls.province.touched && directionForm.controls.province.errors?.required"
                        class="error">Campo obligatorio
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row select-container">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <ng-select bindLabel="name" formControlName="city" placeholder="Población*" (change)="setMsg()">
                        <ng-option value="default">Seleccione una ciudad</ng-option>
                        <ng-option *ngFor="let city of cities" [value]="city.id">{{city.name}},
                          {{directionForm.controls.province.value}}{{city.id}}
                        </ng-option>
                      </ng-select>
                      <div
                        *ngIf="directionForm.controls.city.touched &&
                            (directionForm.controls.city.errors?.required || directionForm.controls.city.errors?.defaultOption)"
                        class="error">Campo obligatorio
                      </div>
                      <div *ngIf="msgInfoDays" class="text-info">
                        <div [innerHTML]="msgInfoDays"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-12">
                    <mat-form-field class="w-100">
                      <mat-label>Dirección</mat-label>
                      <input type="text" class="w-100" placeholder="Ej: Calle Alcalá, 48, 1ºA"
                        formControlName="direction" matInput>
                      <div
                        *ngIf="directionForm.controls.direction.touched && directionForm.controls.direction.errors?.required"
                        class="error">Campo obligatorio
                      </div>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-12">
                    <mat-form-field class="w-100">
                      <mat-label>Teléfono</mat-label>
                      <input type="text" class="w-100" formControlName="phoneNumber" matInput>
                      <div *ngIf="directionForm.controls.phoneNumber.touched
                        && directionForm.controls.phoneNumber.errors?.pattern" class="error">Campo obligatorio
                      </div>
                    </mat-form-field>
                  </div>
                </div>

                <div class="max d-flex justify-content-center">
                  <button type="submit" class="btn btn-dark me-2" (click)="newDirection = false;"
                    style="width: 150px">Cancelar</button>
                  <button type="submit" class="btn btn-primary text-light w-100" *ngIf="directionForm.valid"
                    style="width: 150px">Continuar</button>
                  <button type="submit" class="btn btn-danger" *ngIf="!directionForm.valid"
                    [disabled]="!directionForm.valid" style="width: 150px">Faltan Datos</button>
                </div>
              </form>
            </div>
          </ng-template>
        </div>

        <div class="card">
          <div class="card-header bg-white">
            <h2 class="order-title">
              <span>3. Proceso de Pago</span>
            </h2>
          </div>
          <div class="card-body" *ngIf="!showDirections && !editDirection && isInRoute == 1">
            <div *ngIf="userInfo">
              <div>
                <mat-checkbox class="m-1" [(ngModel)]="aceptoPoliticas">
                  Acepto la <a routerLink="/política-devolucion-y-envio">política de devoluciones y envío</a>
                </mat-checkbox>
              </div>

              <div *ngIf="companyConf.pasarelaActiva">
                <div class="w-100 d-flex justify-content-center mb-2" *ngIf="!storePickup">
                  <button class="btn btn-primary text-light btn-delivery-data"
                    [disabled]="!deliveryDateValid || !aceptoPoliticas" (click)="paymentOrder(AddingOrder)"><i
                      class="far fa-credit-card me-1"></i>Realizar Pago</button>
                </div>
                <div class="row" *ngIf="storePickup">
                  <div class="col-12 mb-2">
                    <button class="btn btn-primary text-light w-100" [disabled]="!deliveryDateValid || !aceptoPoliticas"
                      (click)="paymentOrder(AddingOrder)"><i class="far fa-credit-card me-1"></i>Realizar Pago</button>
                  </div>
                </div>
              </div>

              <div *ngIf="!companyConf.pasarelaActiva" class="w-100">
                <div class="d-flex justify-content-center mb-2 w-100">
                  <button class="btn btn-primary text-light btn-delivery-data" [disabled]="!deliveryDateValid"
                    (click)="makeOrder(AddingOrder)"><i class="fas fa-store-alt"></i> Pagar en tienda</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngTemplateOutlet="resumenPedido"> </ng-container>
    </div>
  </div>
</div>

<!-- ______ SUCCESS ORDER - REGISTER USER____________ -->
<div class="row success-order " *ngIf="showSuccessOrder && userInfo">
  <div class="col-md-2"></div>
  <div class="col-md-8 d-flex justify-content-center">
    <div>
      <div class="text-primary max d-flex justify-content-center">
        <i class="fas fa-thumbs-up fa-5x"></i>
      </div>
      <div class="max d-flex justify-content-center text-center">
        <h1>Su pedido se ha procesado con éxito</h1>
      </div>
      <p>Gracias por realizar tu pedido, puedes ver tus pedidos en la página de
        <a routerLink="/miPerfil">Mi perfil</a>, pulsado sobre
        <a routerLink="/miPerfil/misPedidos">Mis Pedidos.</a>
      </p>

      <div class="max d-flex justify-content-center text-center">
        <h3><b>Resumen del pedido</b></h3>
      </div>

      <div class="border">
        <div class="row">
          <div class="col-md-6">
            <span><i><b>Número de pedido:</b></i> {{orderResult.orderNumber}}<br /></span>
            <span><i><b>Número de artículos</b></i> {{articles.length}}<br /></span>
            <span *ngIf="this.totalLumps"><i><b>Número de bultos:</b></i> {{totalLumps}}<br /></span>
            <i><b>Precio Total:</b></i> <span [style]="'color: ' + companyConf.priceColor + ';'">
              {{(totalIvaPrice | currency: 'EUR')}}</span>
            <br />
            <span *ngIf="totalTransport == 0 && selectedStorePickupDate == null">
              <div *ngIf="homeDelivery; else pickUpStore">
                Entrega <span class="text-danger">GRATUITA</span><br />
              </div>
              <ng-template #pickUpStore>
                RECOGIDA EN TIENDA
              </ng-template>
            </span><br />
          </div>


          <div class="col-md-6">
            <span><i><b>Fecha de entrega:</b></i> {{orderResult.routeDate}}<br /></span>
            <span *ngIf="companyConf.showClientSchedule && userInfo.schedule"><b>Horario:
              </b>{{userInfo.schedule}}</span>
            <span *ngIf="orderResult.routeHour != null && orderResult.routeHour != ''"><i><b>Horario:</b></i>
              {{orderResult.routeHour}}<br /></span>
            <p *ngIf="companyConf.showContactDetails"><i><b>Dirección de entrega</b></i><br />
              <span *ngIf="selectedDirection">
                {{selectedDirection.direction}}<br />
                {{selectedDirection.provinceName}}, {{selectedDirection.cityName}},
                {{selectedDirection.province}}{{selectedDirection.city}}
              </span>

              <span *ngIf="!selectedDirection">
                {{userInfo.direction}}<br />
                {{userInfo.provinceName}}, {{userInfo.cityName}}, {{userInfo.province}}{{userInfo.city}}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-2"></div>
</div>

<!-- ______ SUCCESS ORDER - NO REGISTER USER____________ -->
<div class="row success-order" *ngIf="showSuccessOrder && !userInfo">
  <div class="col-md-2"></div>
  <div class="col-md-8 d-flex justify-content-center">
    <div>
      <div class="text-primary max d-flex justify-content-center">
        <i class="fas fa-thumbs-up fa-5x"></i>
      </div>
      <div class="max d-flex justify-content-center text-center">
        <h1>Su pedido se ha procesado con éxito</h1>
      </div>
      <p>Gracias por realizar tu pedido.</p>

      <div class="max d-flex justify-content-center text-center">
        <h3><b>Resumen del pedido</b></h3>
      </div>

      <div class="border">
        <div class="row">
          <div class="col-md-6">
            <span><i><b>Número de pedido:</b></i> {{orderResult.orderNumber}}<br /></span>
            <span><i><b>Número de artículos</b></i> {{articles.length}}</span><br />
            <i>
              <b>Precio Total:</b></i>
            <span [style]="'color: ' + companyConf.priceColor + ';'">
              {{(totalIvaPrice | currency: 'EUR')}}
            </span><br />
            <span *ngIf="totalTransport == 0 && selectedStorePickupDate == null">
              <div *ngIf="homeDelivery; else pickUpStore">
                Entrega <span class="text-danger">GRATUITA</span><br />
              </div>
              <ng-template #pickUpStore>
                RECOGIDA EN TIENDA
              </ng-template>
            </span><br />
          </div>

          <div class="col-md-6" *ngIf="selectedStorePickupDate">
            <span><i><b>Día de recogida:</b></i> {{orderResult.routeDate}} </span><br />
            <span><i><b>Horario:</b></i> {{orderResult.routeHour}} </span><br />
          </div>

          <div class="col-md-6" *ngIf="selectedDirection">
            <p><i><b>Dirección de entrega</b></i><br />
              <span>
                {{selectedDirection.direction}}<br />
                {{selectedDirection.provinceName}}, {{selectedDirection.cityName}},
                {{selectedDirection.province}}{{selectedDirection.city}}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-2"></div>
</div>

<div class="container" *ngIf="tpvParameters != undefined">
  <div *ngIf="tpvParameters.paycomet_url != undefined; else CECA">
    <iframe title="titulo" sandbox="allow-top-navigation allow-scripts allow-same-origin allow-forms"
      [src]="tpvParameters.paycomet_url | safe"
      onload="this.style.height=(this.contentWindow.document.body.scrollHeight+20)+'px';" width="100%" height="2000"
      frameborder="0" marginheight="0" marginwidth="0" scrolling="no"
      style="border: 0px solid #000000; padding: 0px; margin: 0px"></iframe>
  </div>
</div>

<ng-template #CECA>
  <div *ngIf="tpvParameters.ceca; else REDSYS">
    <!-- ENCTYPE="application/x-www-form-urlencoded https://tpv.ceca.es/tpvweb/tpv/compra.action"-->
    <FORM id='redirectFormCeca' action="{{companyConf.tpvUrl}}" method="POST" name="from"
      ENCTYPE="application/x-www-form-urlencoded">
      <INPUT name="MerchantID" type="hidden" value="{{tpvParameters.merchantID}}">
      <INPUT name="AcquirerBIN" type="hidden" value="{{tpvParameters.acquirerBIN}}">
      <INPUT name="TerminalID" type="hidden" value="{{tpvParameters.terminalID}}"><!--{{tpvParameters.terminalID}}-->
      <INPUT name="Num_operacion" type="hidden" value="{{tpvParameters.num_operacion}}">
      <INPUT name="Importe" type="hidden" value="{{tpvParameters.importe}}">
      <INPUT name="TipoMoneda" type="hidden" value="978">
      <INPUT name="Exponente" type="hidden" value="2">
      <INPUT name="URL_OK" type="hidden" value="{{tpvParameters.url_ok}}">
      <INPUT name="URL_NOK" type="hidden" value="{{tpvParameters.url_nok}}">

      <INPUT name="Firma" type="hidden" value="{{tpvParameters.firma}}">
      <INPUT name="Cifrado" type="hidden" value="SHA2">
      <INPUT name="Pago_soportado" type="hidden" value="SSL">
      <INPUT name="Idioma" type="hidden" value="1">
    </FORM>
  </div>
</ng-template>

<ng-template #REDSYS>
  <!-- OCCULT FORM TPV -->
  <form id='redirectForm' name="from" action="{{companyConf.tpvUrl}}" method="POST">
    <input name="Ds_SignatureVersion" type="hidden" value="{{tpvParameters.ds_SignatureVersion}}" />
    <input name="Ds_MerchantParameters" type="hidden" value="{{tpvParameters.ds_MerchantParameters}}" />
    <input name="Ds_Signature" type="hidden" value="{{tpvParameters.ds_Signature}}" />
  </form>
</ng-template>

<ng-template #AddingOrder let-modal class="modal">
  <div class="modal-body">
    <div class="container">
      <div class="w-100 font-weight-bold w-100 text-center">
        <h1 class="text-dark">Tramitando pedido</h1>
        <div class="w-100 d-flex justify-content-center mb-3">
          <img src="assets/loading/loading.gif"  alt="Cargando icono" title="Icono cargando datos"
            style="height: 150px !important; width: auto !important;" />
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #resumenPedido>
  <div class="col-md-5  bg-light-gray pb-3 pt-5 ps-4 pe-4 mb-5">
    <div class="text-danger font-size-title">
      Total: {{articles.length}} producto<span *ngIf="articles.length != 1">s</span>
    </div>

    <div class="text-dark mt-4 pb-3 font-size-title">
      <div class="row">
        <div class="col-8">
          Subtotal
        </div>
        <div class="col-4 text-end">
          {{(totalIvaPrice | currency: 'EUR')}}
        </div>
      </div>
    </div>

    <div class="text-dark mt-3 font-size-title">
      <div class="row">
        <div class="col-5">
          Envío
        </div>
        <div class="col-7 text-end">
          <div  *ngIf="(codigoPostal != undefined && codigoPostalValido) || homeDelivery; else cpNoValido">

            <div *ngIf="!homeDelivery || ((totalIvaPrice >= minPrice || minPrice == undefined) && (totalTransport == 0 || totalTransport == undefined))">
              <div *ngIf="homeDelivery; else pickUpStore">
                GRATUITO
              </div>
              <ng-template #pickUpStore>
                RECOGIDA EN TIENDA
              </ng-template>
            </div>

            <div *ngIf="totalIvaPrice < minPrice && (totalTransport == 0 || totalTransport == undefined) && !storePickup && !selectDirection">
              <div *ngIf="missingPrice">
                FALTAN <span class="text-danger">{{(missingPrice | currency: 'EUR')}}</span>
              </div>
            </div>

            <div *ngIf="priceRoute">
              <div *ngIf="!(priceRoute.minKg == 0 && priceRoute.deliveryPrice == 0 && priceRoute.deliveryKg == 0) && totalTransport > 0">
                {{totalTransport  | currency: 'EUR'}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-dark mt-3 font-size-title" *ngIf="userDiscount != 0">
      <div class="row">
        <div class="col-5">
          Descuento
        </div>
        <div class="col-7 text-end">
          {{userDiscount}} %
        </div>
      </div>
    </div>

    <div class="text-dark mt-3 font-size-title border-top border-light pt-4 mt-4">
      <div class="row">
        <div class="col-8">
          Total (impuestos inc.)
        </div>
        <div class="col-4 text-end">
          {{(totalIvaPrice + totalTransport | currency: 'EUR')}}
        </div>
      </div>
    </div>

    <div class="card" *ngIf="articuloRegalo != undefined">
      <div class="card-header bg-white text-black-50" *ngIf="!contactValid || !directionValid">
        <h2 class="order-title">Preparar para regalo</h2>
      </div>

      <div class="card-body">
        <div class="d-flex w-100">
          <img *ngIf="articuloRegalo.articleImgUrl; else productImg"
               src="assets/fotos/articulos/{{articuloRegalo.articleImgUrl.toLowerCase()}}" alt="Foto del artículo"
               width="100px" height="auto" />
          <ng-template #productImg>
            <img *ngIf="articuloRegalo.productImgUrl"
                 src="assets/fotos/productos/{{articuloRegalo.productImgUrl.toLowerCase()}}"
                 alt="Foto del artículo" width="100px" height="auto" />
          </ng-template>

          <div class="ms-2">
            <div class="mb-2" style="font-size: 13px">
              {{articuloRegalo.name}}
            </div>
            <div class="font-weight-bolder">
              {{articuloRegalo.priceIva.toFixed(2).replace('.', ',')}} €
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-center w-100 mt-3">
          <button class="btn btn-primary text-light" *ngIf="!prepararRegalo; else existeRegalo"
                  (click)="prepararRegalo = true; addArticuloRegalo()">
            Añadir al pedido
          </button>

          <ng-template #existeRegalo>
            <div class="d-flex">
              <i class="fa-solid fa-check fa-2x text-success"></i>
              <div class="h-100 d-flex align-items-center ms-2">
                <b>Preparar para regalo</b>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</ng-template>


